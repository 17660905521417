import React, { useState } from 'react';

import { FiEdit } from 'react-icons/fi';

import { Modal, Select } from 'antd';

import Input from '../../../Input';

import { DropdownItem, ModalContainer, ButtonsContainer, CancelButton, ConfirmButton, CategoryInput } from '../../styles';

function UpdateBird({ bird, onUpdate, categories }) {
  const id = bird.birdId;
  const [name, setName] = useState(bird.name);
  const [categoryId, setCategoryId] = useState(bird.category.id);
  const [price, setPrice] = useState(bird.price);
  const [visible, setVisible] = useState(false);
  const { Option } = Select;

  function handleUpdate(e) {
    e.preventDefault();

    onUpdate({
      id,
      name,
      categoryId,
      price
    });

    setName('');
    setCategoryId('');
    setPrice('');

    setVisible(false);
  }
  async function handleChange(value){
    await setCategoryId(value);
  }

  return (
    <>
      <DropdownItem onClick={() => setVisible(true)}>
        <FiEdit /> Editar
      </DropdownItem>
      <Modal
        title="Editar ave"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <ModalContainer>
          <form onSubmit={handleUpdate}>
            <Input
              type="text"
              name="raca"
              label="Raça"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
            <CategoryInput>
              <label>Categoria</label>
              <Select 
                value={categoryId} 
                style={{ width: "100%" }} 
                size = "large"
                onChange={handleChange}
              >
                {
                  categories.map(category => (
                    <Option value={category.id}>{category.name}</Option>
                  ))
                }
              </Select>
            </CategoryInput>
            <Input
              type="text"
              name="preco"
              label="Preço"
              value={price}
              onChange={e => setPrice(e.target.value)}
              required
            />
            <ButtonsContainer>
              <CancelButton type="reset" onClick={() => setVisible(false)}>Cancelar</CancelButton>
              <ConfirmButton type="submit">Editar</ConfirmButton>
            </ButtonsContainer>
          </form>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default UpdateBird;