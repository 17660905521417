import React, { createContext, useState, useContext, useEffect } from 'react';

import { productsApi } from '../services';

const ProductsContext = createContext();

function ProductsProvider({ children }) {
  const [products, setProducts] = useState([]);
  const [aux, setAux] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getProducts = async () => {
      try {
        const response = await productsApi.read();
        if (response.status === 200) {
          setProducts(response.data.sort((a, b) => a.name.localeCompare(b.name)));
        }
      } catch (error) {
        setProducts([]);
      }
    }
    getProducts();
    setLoading(false);
  }, [aux]);

  return (
    <ProductsContext.Provider value={{ 
      products, 
      setProducts, 
      aux, 
      setAux, 
      loading, 
      setLoading 
    }}>
      {children}
    </ProductsContext.Provider>
  )
}

export default ProductsProvider;

export function useProducts() {
  const context = useContext(ProductsContext);
  const { products, setProducts, aux, setAux, loading, setLoading } = context;

  return { products, setProducts, aux, setAux, loading, setLoading };
}