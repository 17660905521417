import React, { useState } from 'react';
import moment from 'moment';
import { PieChart, Pie, Sector, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import { graphicsApi } from '../../../../services';

import FormTitle from '../../../../components/FormTitle';
import Input from '../../../../components/Input';

import { FiArrowRight } from 'react-icons/fi';

import { Empty, message } from 'antd';

import { GeneralReport, InputGroup, ReportContainer, TooltipStyle } from './styles';
import { StyledTable } from '../../styles';

function Graphics() {
  const [initDate, setInitDate] = useState([]);
  const [finishDate, setFinishDate] = useState([]);
  const [isReportMade, setIsReportMade] = useState(false);
  const [birdsData, setBirdsData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [activeBirdsIndex, setActiveBirdsIndex] = useState(0);
  const [activeProductsIndex, setActiveProductsIndex] = useState(0);
  const [birdsTotalAmount, setBirdsTotalAmount] = useState(0);
  const [productsTotalAmount, setProductsTotalAmount] = useState(0);
  const [birdsTotalBilling, setBirdsTotalBilling] = useState(0);
  const [productsTotalBilling, setProductsTotalBilling] = useState(0);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  function renderActiveShape(props) {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle,
      fill, payload, value } = props;
    return (
      <g>
        <text x={cx} y={cy} dy={0} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <text x={cx} y={cy} dy={16} textAnchor="middle" fill={fill}>
          {`Qntd: ${value}`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    );
  };

  function onBirdsPieEnter(_, index) {
    setActiveBirdsIndex(index);
  };

  function onProductsPieEnter(_, index) {
    setActiveProductsIndex(index);
  };

  function createBirdData(birds) {
    var index = 0;
    const bData = [];

    for (var id in birds) {
      bData[index] = {
        name: birds[id].name,
        amount: birds[id].amount,
        billing: birds[id].billing
      };
      setBirdsTotalAmount(oldValue => oldValue + birds[id].amount);
      setBirdsTotalBilling(oldValue => oldValue + birds[id].billing);
      index++;
    };

    setBirdsData(bData.sort((a, b) => b.billing - a.billing));
  }

  function createProductsData(products) {
    var index = 0;
    const pData = [];

    for (var id in products) {
      pData[index] = {
        name: products[id].name,
        amount: products[id].amount,
        billing: products[id].billing
      };
      setProductsTotalAmount(oldValue => oldValue + products[id].amount);
      setProductsTotalBilling(oldValue => oldValue + products[id].billing);
      index++;
    };

    setProductsData(pData.sort((a, b) => b.billing - a.billing));
  }

  async function createReport() {
    const startDate = moment(initDate).format();
    const endDate = moment(finishDate).format("YYYY-MM-DDT23:59:59Z");
    try {
      message.loading({
        key: "creating",
        content: "Gerando relatório",
        duration: 9999
      });
      const response = await graphicsApi.create({
        startDate,
        endDate,
      });
      if (response.status === 200) {
        setBirdsTotalAmount(0);
        setBirdsTotalBilling(0);
        setProductsTotalAmount(0);
        setProductsTotalBilling(0);
        const { birds, products } = response.data;
        setIsReportMade(true);
        createBirdData(birds);
        createProductsData(products);
        message.destroy("creating");
        message.success("Relatório gerado com sucesso!");
      }
    } catch (error) {
      message.destroy("creating");
      message.error("Erro ao gerar o relatório, tente novamente...");
    }
  }

  function getDate() {
    const isTrue = moment(moment(initDate).format()).isBefore(moment(finishDate).format());
    if (isTrue !== false) {
      createReport();
    } else {
      message.error('Por favor, selecione a data corretamente');
    }
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div>
          <TooltipStyle>
            Faturamento: R$ {`${payload[0].value.toFixed(2)}`}
          </TooltipStyle>
        </div>
      );
    }

    return null;
  };

  const birdsColumns = [
    {
      title: 'Raça',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Quantidade',
      key: 'amount',
      dataIndex: 'amount',
      ellipsis: 'true'
    },
    {
      title: 'Faturamento',
      key: 'billing',
      dataIndex: 'billing',
      render: (billing) => (
        `R$${billing.toFixed(2)}`
      ),
      ellipsis: 'true'
    },
  ]

  const productsColumns = [
    {
      title: 'Produto',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Quantidade',
      key: 'amount',
      dataIndex: 'amount',
      ellipsis: 'true'
    },
    {
      title: 'Faturamento',
      key: 'billing',
      dataIndex: 'billing',
      render: (billing) => (
        `R$${billing.toFixed(2)}`
      ),
      ellipsis: 'true'
    },
  ]

  return (
    <>
      Selecione o período:
      <InputGroup>
        <Input
          type="date"
          name="initDate"
          value={initDate}
          onChange={e => setInitDate(e.target.value)}
          required
        />
        <FiArrowRight />
        <Input
          type="date"
          name="finishDate"
          value={finishDate}
          onChange={e => setFinishDate(e.target.value)}
          required
        />
        <button type="button" onClick={getDate} >Pesquisar</button>
      </InputGroup>

      {isReportMade ? (
        <>
          <ReportContainer>
            <FormTitle title="Relatório geral de aves" />
            <GeneralReport>
              <span>Quantidade total: {birdsTotalAmount} aves</span>
              <span>Faturamento total: R${birdsTotalBilling.toFixed(2)}</span>
            </GeneralReport>
            <StyledTable scroll={{ x: true }} pagination={true}
              columns={birdsColumns} dataSource={birdsData} rowKey="name" />
          </ReportContainer>

          <ReportContainer>
            <FormTitle title="Quantidade de aves vendidas" />
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  activeIndex={activeBirdsIndex}
                  activeShape={renderActiveShape}
                  data={birdsData}
                  innerRadius={80}
                  outerRadius={120}
                  fill="#8884d8"
                  paddingAngle={2}
                  dataKey="amount"
                  onMouseEnter={onBirdsPieEnter}
                >
                  {birdsData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </ReportContainer>

          <ReportContainer>
            <FormTitle title="Faturamento de aves" />
            <ResponsiveContainer width="100%" height={1000} maxHeight="auto">
              <BarChart data={birdsData} layout="vertical" >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={80} />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey={`billing`} fill="#00C49F" maxBarSize={60} />
              </BarChart>
            </ResponsiveContainer>
          </ReportContainer>

          <ReportContainer>
            <FormTitle title="Relatório geral de produtos" />
            <GeneralReport>
              <span>Quantidade total: {productsTotalAmount} produtos</span>
              <span>Faturamento total: R${productsTotalBilling.toFixed(2)}</span>
            </GeneralReport>
            <StyledTable scroll={{ x: true }} pagination={true}
              columns={productsColumns} dataSource={productsData} rowKey="name" />
          </ReportContainer>

          <ReportContainer>
            <FormTitle title="Quantidade de produtos vendidos" />
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  activeIndex={activeProductsIndex}
                  activeShape={renderActiveShape}
                  data={productsData}
                  innerRadius={80}
                  outerRadius={120}
                  fill="#8884d8"
                  paddingAngle={2}
                  dataKey="amount"
                  onMouseEnter={onProductsPieEnter}
                >
                  {productsData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </ReportContainer>

          <ReportContainer>
            <FormTitle title="Faturamento de produtos" />
            <ResponsiveContainer width="100%" height={800}>
              <BarChart data={productsData} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={80} />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey={`billing`} fill="#00C49F" maxBarSize={60} />
              </BarChart>
            </ResponsiveContainer>
          </ReportContainer>
        </>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Relatório não gerado" />
      )}
    </>
  );
}

export default Graphics;