import React from 'react';

import { TopContainer } from './styles';

function Header({ title, description }) {
  return(
    <TopContainer>
      <h1>{title}</h1>
      <p>{description}</p>
    </TopContainer>
  );
}

export default Header;