import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { logout } from '../../services/auth';
import { useUser } from '../../context/User';

import { FiUsers, FiMap, FiLogOut, FiUser, FiTrendingUp } from 'react-icons/fi';
import { IoMdMenu, IoMdClose } from 'react-icons/io';

import { Menu, message } from 'antd';

import { Nav, NavMenu, RightMenu, Title, StyledMenu, DropdownItem } from './styles';

function Navbar() {
  const { user } = useUser();
  const history = useHistory();
  const [clicked, setClicked] = useState(false);

  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => setClicked(!clicked)}>
        <DropdownItem>
          <FiUser /> {user.name}
        </DropdownItem>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1" onClick={() => setClicked(!clicked)}>
        <DropdownItem>
          <Link to="/system/clients" ><FiMap /> Clientes</Link>
        </DropdownItem>
      </Menu.Item>
      <Menu.Divider />
      {user.level === 0 ? (
        <>
          <Menu.Item key="2" onClick={() => setClicked(!clicked)}>
            <DropdownItem>
              <Link to="/system/admins" ><FiUsers /> Admins</Link>
            </DropdownItem>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="3" onClick={() => setClicked(!clicked)}>
            <DropdownItem>
              <Link to="/system/management" ><FiTrendingUp /> Gestão</Link>
            </DropdownItem>
          </Menu.Item>
          <Menu.Divider />
        </>
      ) : (
        null
      )}
      <Menu.Item key="4" onClick={redirectToLogin}>
        <DropdownItem>
          <FiLogOut /> Sair
        </DropdownItem>
      </Menu.Item>
    </Menu>
  );

  function redirectToHome(e) {
    e.preventDefault();
    history.push('/system');
  }

  function handleMenu(e) {
    e.preventDefault();
    setClicked(!clicked);
  }

  function redirectToLogin() {
    setClicked(!clicked);
    message.loading({
      key: "loggingOut",
      content: "Deslogando...",
      duration: 9999
    });
    logout();
    setTimeout(() => {
      history.push('/login');
      message.destroy("loggingOut");
    }, 1000)
  }

  return (
    <Nav>
      <Title>
        <h1 onClick={e => redirectToHome(e)} title="Início">Aves Mansas</h1>
      </Title>
      <NavMenu>
        <Link to="/system/clients" title="Clientes"><FiMap /> Clientes</Link>
        {user.level === 0 ? (
          <>
            <Link to="/system/admins" title="Admins"><FiUsers /> Admins</Link>
            <Link to="/system/management" title="Gestão"><FiTrendingUp /> Gestão</Link>
          </>
        ) : (
          null
        )}
      </NavMenu>
      <RightMenu>
        <span>{user.name}</span>
        <FiLogOut onClick={redirectToLogin} title="Sair" />
      </RightMenu>
      <StyledMenu overlay={menu} trigger={['click']} onVisibleChange={() => setClicked(!clicked)}>
        {clicked ?
          <IoMdClose onClick={handleMenu} /> :
          <IoMdMenu onClick={handleMenu} />}
      </StyledMenu>
    </Nav>
  );
}

export default Navbar;