import api from './api';

const sellsApi = {
  create: (data) => api.post('/sell/register', data),
  readAll: () => api.get('/sell/allSells'),
  readOne: (id) => api.get(`/sell/${id}`),
  update: (data) => api.put('/sell/update', data),
  delete: (id) => api.delete(`/sell/delete/${id}`)
}

export default sellsApi;