import styled from 'styled-components';

export const HomeSection = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: var(--color-content-background);
  box-shadow: 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.3);
  margin: 2rem 0;
  padding: 2rem;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img { 
    width: 128px;
    margin-bottom: 2rem;
  }
  
  h1 {
    color: var(--color-title-text);
    text-align: center;
    font-size: 2.4rem;
    font-weight: 700;
  }

  @media (min-width: 768px) {
    max-width: 1024px;
    margin: 2rem auto;
  }
`;