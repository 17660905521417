import React, { useState } from 'react';

import { FiEdit } from 'react-icons/fi';
import { IoIosPin } from 'react-icons/io';

import { Modal } from 'antd';

import Input from '../../../Input';
import NumberInput from '../../../NumberInput';
import Radio from '../../../Radio';

import { DropdownItem, ModalContainer, ButtonsContainer, CancelButton, ConfirmButton, RadioGroup } from '../../styles';

function UpdateClient({ client, onUpdate }) {
  const { id, age, postalCode, interest, time, visit, lat, lon } = client;
  const [name, setName] = useState(client.name);
  const [whatsApp, setWhatsApp] = useState(client.whatsApp);
  const [freight, setFreight] = useState((client.freight).toFixed(2));
  const [color, setColor] = useState(client.color);
  const [visible, setVisible] = useState(false);

  async function handleUpdate(e) {
    e.preventDefault();

    onUpdate({
      id, 
      age, 
      postalCode, 
      interest, 
      time, 
      visit, 
      lat, 
      lon,
      name,
      whatsApp,
      freight,
      color
    });
    
    setVisible(false);
  }

  return (
    <>
      <DropdownItem onClick={() => setVisible(true)}>
        <FiEdit /> Editar
      </DropdownItem>
      <Modal
        title="Editar cliente"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <ModalContainer>
          <form onSubmit={handleUpdate}>
            <Input
              type="text"
              name="name"
              label="Nome"
              value={name}
              onChange={ e => setName(e.target.value) }
              required
            />
            <NumberInput
              name="whatsApp"
              label="WhatsApp"
              format="(##)#####-####" 
              mask="_"
              pattern={"[(][0-9]{2}[)][0-9]{5}-[0-9]{4}"}
              value={whatsApp}
              onChange={ e => setWhatsApp(e.target.value) }
              required
            />
            <NumberInput 
              name="freight"
              label="Frete"
              value={freight}
              onChange={ e => setFreight(e.target.value) }
              required
            />
            <RadioGroup onChange={e => setColor(e.target.value)}>
              <p>Marcação</p>
              <Radio
                required={true}
                id="green"
                name="color"
                label={<IoIosPin title="Verde" style={{ color: 'var(--color-primary)' }}/>}
                value="green"
                checked={color === "green" ? true : false}
              />
              <Radio
                id="yellow"
                name="color"
                label={<IoIosPin title="Amarelo" style={{ color: '#FEDD00' }}/>}
                value="yellow"
                checked={color === "yellow" ? true : false}
              />
              <Radio
                id="red"
                name="color"
                label={<IoIosPin title="Vermelho" style={{ color: '#EF3340' }}/>}
                value="red"
                checked={color === "red" ? true : false}
              />
            </RadioGroup>
            <ButtonsContainer>
              <CancelButton type="reset" onClick={() => setVisible(false)}>Cancelar</CancelButton>
              <ConfirmButton type="submit">Editar</ConfirmButton>
            </ButtonsContainer>
          </form>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default UpdateClient;