import React, { useState } from 'react';

import { FiPlus } from 'react-icons/fi';

import { Modal } from 'antd';

import Input from '../../../Input';

import { ModalContainer, RegisterButton } from '../../styles';

function CreateAdmin({ onCreate }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);

  async function handleCreate(e) {
    e.preventDefault();

    onCreate({
      name,
      email,
      password
    });

    setVisible(false);
    setName('');
    setEmail('');
    setPassword('');
  }

  return (
    <>
      <button type="button" onClick={() => setVisible(true)} title="Cadastrar">
        <FiPlus />
      </button>
      <Modal
        title="Cadastrar admin"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <ModalContainer>
          <form onSubmit={handleCreate}>
            <Input
              type="text"
              name="name"
              label="Nome"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
            <Input
              type="email"
              name="email"
              label="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
            <Input
              type="password"
              name="password"
              label="Senha"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
            <RegisterButton type="submit">Cadastrar</RegisterButton>
          </form>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default CreateAdmin;