import styled from 'styled-components';

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 200px;
  margin-top: 1rem;

  input {
    max-width: 24rem;
  }

  button {
    width: 100%;
    height: 4rem;
    background: var(--color-primary);
    color: var(--color-highlight-text);
    border: 0;
    border-radius: 0.6rem;
    cursor: pointer;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: opacity 0.2s;
    margin-top: 1rem;
  }

  button:hover {
    opacity: 0.8;
  }

  svg {
    color: var(--color-primary);
    width: 10rem;
    height: 4rem;
    transform: rotate(90deg);
  }
  
  @media (min-width: 768px) {
    max-width: 768px;
    flex-direction: row;

    svg {
      width: 10rem;
      height: 4rem;
      transform: rotate(360deg);
    }

    button {
      margin-left: 1.5rem;
      margin-top: 0;
    }
    
  }
`;

export const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
`;

export const TooltipStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-content-background);
  box-shadow: 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.3);
  padding: 2rem;
  border-radius: 0.4rem;
  
  font-weight: bold;
`;

export const GeneralReport = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  font-weight: 600;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;