import React from 'react';

import { usePayments } from '../../../../context/Payments';
import { paymentsApi } from '../../../../services';

import { message } from 'antd';

import CreatePayment from '../../../../components/Managers/PaymentManager/CreatePayment';
import PaymentManager from '../../../../components/Managers/PaymentManager';

import { AddButton, StyledTable } from '../../styles';

function Payments() {
  const { payments, loading, setNewPayment } = usePayments();

  async function createPayment(values) {
    const { name } = values;
    try {
      message.loading({
        key: "creating",
        content: "Cadastrando via de pagamento...",
        duration: 9999
      });
      const response = await paymentsApi.create({
        name
      });
      if (response.status === 200) {
        setNewPayment(response.data);
        message.destroy("creating");
        message.success("Via de pagamento cadastrada com sucesso!");
      }
    } catch (error) {
      console.log(error)
      message.destroy("creating");
      message.error("Erro ao cadastrar a via de pagamento, tente novamente...");
    }
  }

  async function updatePayment(values) {
    const { id, name } = values;
    try {
      message.loading({ 
        key: "updating",
        content: "Editando via de pagamento...",
        duration: 9999
      });
      const response = await paymentsApi.update({
        id,
        name
      });
      if (response.status === 200) {
        setNewPayment(response.data);
        message.destroy("updating");
        message.success("Via de pagamento editada com sucesso!");
      }
    } catch (error) {
      message.destroy("updating");
      message.error("Erro ao editar a via de pagamento, tente novamente...");
    }
  }

  async function deletePayment(id) {
    try {
      message.loading({ 
        key: "deleting",
        content: "Removendo via de pagamento...",
        duration: 9999
      });
      const response = await paymentsApi.delete(id);
      if (response.status === 200) {
        setNewPayment(response.data);
        message.destroy("deleting");
        message.success("Via de pagamento removida com sucesso!");
      }
    } catch (error) {
      message.destroy("deleting");
      message.error("Erro ao remover via de pagamento, tente novamente...");
    }
  }

  const columns = [
    {
      title: 'Via de pagamento',
      key: 'name',
      dataIndex: 'name',
      ellipsis: 'true'
    },
    {
      title: 'Configurações',
      key: 'config',
      render: (payment) => (
        <PaymentManager 
          payment={payment}
          onDelete={deletePayment}
          onUpdate={updatePayment}
        />
      ),
    },
  ];

  return (
    <>
      <AddButton>
        <CreatePayment onCreate={createPayment} />
      </AddButton>
      <StyledTable scroll={{ x: true }} pagination={true} loading={loading}
        columns={columns} dataSource={payments} rowKey="id" />
    </>
  );
}

export default Payments;
