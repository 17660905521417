import React from 'react';

import Routes from './routes';
import UserProvider from './context/User';

import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';
import 'antd/dist/antd.css';
import GlobalStyles from './styles/GlobalStyles';

function App() {
  return (
    <ConfigProvider locale={ptBR}>
      <UserProvider>
        <Routes />
        <GlobalStyles />
      </UserProvider>
    </ConfigProvider>
  );
}

export default App;
