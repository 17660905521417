import React, { useState } from 'react';

import { FiTrash2 } from 'react-icons/fi';

import { Modal } from 'antd';

import { CancelButton, ConfirmButton, ModalContainer, ButtonsContainer, 
  DropdownItem } from '../../styles';

function DeleteProduct({ product, onDelete }) {
  const [visible, setVisible] = useState(false);

  function handleDelete() {
    onDelete(product.productId);
    setVisible(false);
  }

  return (
    <>
      <DropdownItem onClick={() => setVisible(true)}>
        <FiTrash2 /> Remover
      </DropdownItem>
      <Modal
        title="Remover produto"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <ModalContainer>
          <span>Deseja remover esse produto?</span>
          <ButtonsContainer>
            <CancelButton type="reset" onClick={() => setVisible(false)}>Não</CancelButton>
            <ConfirmButton type="submit" onClick={handleDelete}>Sim</ConfirmButton>
          </ButtonsContainer>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default DeleteProduct;