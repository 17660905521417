import React from 'react';

import NumberFormat from 'react-number-format';

import { InputBlock } from './styles';

function NumberInput({ name, label, value, ...rest }) {
  return(
    <InputBlock>
      <label htmlFor={name} >{label}</label>
      <NumberFormat name={name} value={value} {...rest} />
    </InputBlock>
  );
}

export default NumberInput;