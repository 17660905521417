import React from 'react';

import { TitleContainer } from './styles';

function FormTitle({ title }) {
  return (
    <TitleContainer>
      <span>{title}</span>
    </TitleContainer>
  );
}

export default FormTitle;