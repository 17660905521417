import styled from 'styled-components';

export const RadioBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.6rem;

  input {
    margin-right: 0.8rem;
    background: var(--color-input-background);
  }

  label {
    display: flex;
    align-items: center;

    svg {
      font-size: 1.8rem;
    }
  }

  @media (min-width: 768px) {
    input {
      margin-right: 1rem;
    }
  }
`;