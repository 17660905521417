import React, { useState } from 'react';

import { FiPlus } from 'react-icons/fi';

import { Modal, Select } from 'antd';

import Input from '../../../Input';

import { ModalContainer, CategoryInput, RegisterButton } from '../../styles';

function CreateCategory({ onCreate }) {
  const [name, setName] = useState('');
  const [type, setType] = useState(null);
  const [visible, setVisible] = useState(false);
  const { Option } = Select;

  async function handleCreate(e) {
    e.preventDefault();

    onCreate({
      name,
      type
    });

    setVisible(false);
    setName('');
    setType(null);
  }

  async function handleChange(value) {
    await setType(value);
  }

  return (
    <>
      <button type="button" onClick={() => setVisible(true)} title="Cadastrar">
        <FiPlus />
      </button>
      <Modal
        title="Cadastrar categoria"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <ModalContainer>
          <form onSubmit={handleCreate}>
            <Input
              type="text"
              name="name"
              label="Nome"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
            <CategoryInput>
              <label>Categoria</label>
              <Select 
                value={type} 
                placeholder="Selecione uma categoria" 
                size="large"
                style={{ width: "100%" }} 
                onChange={handleChange}
              >
                <Option value="bird">Ave</Option>
                <Option value="product">Produto</Option>
              </Select>
            </CategoryInput>
            <RegisterButton type="submit">Cadastrar</RegisterButton>
          </form>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default CreateCategory;