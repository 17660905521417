import styled from 'styled-components';

import { Table } from 'antd';

export const NavRouter = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const SectionContainer = styled.div`
  width: 100vw;
  max-width: 768px;
  background-color: var(--color-content-background);
  box-shadow: 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.3);
  margin: 2rem 0;
  padding: 2rem;
  border-radius: 0.4rem;

  @media (min-width: 768px) {
    max-width: 1024px;
    margin: 2rem auto;
  }
`;

export const AddButton = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    width: 7.2rem;
    height: 4.2rem;
    background: var(--color-primary);
    color: var(--color-highlight-text);
    border: 0;
    border-radius: 0.6rem;
    cursor: pointer;
    font-size: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: opacity 0.2s;
  
    :hover {
      opacity: 0.8;
    }

    svg {
      stroke-width: 3;
    }
  }

  @media (min-width: 700px) {
    max-width: 100vw;
  }
`;

export const StyledTable = styled(Table)`
  width: 100%;
  margin-top: 2rem;

  tr > th, td {
    text-align: center;
  }

  td {
    max-width: 250px;
    min-width: 100px;
    word-break: break-word;

    svg {
      cursor: pointer;
      font-size: 2.4rem;

      :hover {
        opacity: 0.4;
        transition: 0.2s;
      }
    }
  }

  th {
    svg {
      font-size: 1.6rem;
    }
  }
`;