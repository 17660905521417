import React from 'react';

import { DistanceMatrixService } from '@react-google-maps/api';

function Distance({ latO, lngO, latD, lngD, setFreight, setDistance }) {

  return (
    <>
      <DistanceMatrixService
        options={{
          destinations: [{ lat: latD, lng: lngD }],
          origins: [{ lng: lngO, lat: latO }],
          travelMode: "DRIVING",
        }}
        callback={(res) => {


          let distance = Math.round((res.rows[0].elements[0].distance.value * 2) / 10);
          distance = distance / 100;
          const freight = distance + 6;
          setFreight(freight.toFixed(2));
          setDistance(Math.round(distance));
        }}
      />
    </>
  );
}

export default Distance;