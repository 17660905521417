import React from 'react';

import { FiMoreHorizontal } from 'react-icons/fi';

import { Dropdown, Menu } from 'antd';

import UpdateCategory from './UpdateCategory';
import DeleteCategory from './DeleteCategory';

function CategoryManager({ category, onDelete, onUpdate }) {

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <UpdateCategory category={category} onUpdate={onUpdate} />
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <DeleteCategory category={category} onDelete={onDelete} />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="topCenter">
      <FiMoreHorizontal />
    </Dropdown>
  );
}

export default CategoryManager;