import React, { useState } from 'react';

import { FiPlus } from 'react-icons/fi';

import { Modal, Select } from 'antd';

import Input from '../../../Input';
import NumberInput from '../../../NumberInput';

import { CategoryInput, ModalContainer, RegisterButton } from '../../styles';

function CreateProduct({ onCreate, categories }) {
  const [name, setName] = useState('');
  const [categoryId, setCategoryId] = useState(null);
  const [price, setPrice] = useState('');
  const [visible, setVisible] = useState(false);
  const { Option } = Select;

  function handleCreate(e) {
    e.preventDefault();

    onCreate({
      categoryId,
      name,
      price
    });

    setName('');
    setCategoryId(null);
    setPrice('');

    setVisible(false);
  }

  function handleChange(value) {
    setCategoryId(value);
  }

  return (
    <>
      <button type="button" onClick={() => setVisible(true)} title="Adicionar">
        <FiPlus />
      </button>
      <Modal
        title="Adicionar produto"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <ModalContainer>
          <form onSubmit={handleCreate}>
            <Input
              type="text"
              name="name"
              label="Nome"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
            <CategoryInput>
              <label>Categoria</label>
              <Select 
                value={categoryId} 
                style={{ width: "100%" }} 
                size="large"
                placeholder="Selecione uma categoria"
                onChange={handleChange}
              >
                {
                  categories.map(category => (
                    <Option key={category.id} value={category.id}>{category.name}</Option>
                  ))
                }
              </Select>
            </CategoryInput>
            <NumberInput
              name="price"
              label="Preço"
              value={price}
              onChange={e => setPrice(e.target.value)}
              required
            />
            <RegisterButton type="submit">Adicionar</RegisterButton>
          </form>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default CreateProduct;