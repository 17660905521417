import React from 'react';

import { FooterContainer } from './styles';

function Footer() {
  return (
    <FooterContainer>
      <span>
        Feito com
        <span role="img" aria-label="blue-heart"> 💙 </span>
        por
        <a href="https://www.ejcomp.com.br/" target="_blank" rel="noopener noreferrer"> EJComp </a>
      </span>
    </FooterContainer>
  );
}

export default Footer;