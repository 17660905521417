import React from 'react';

import { useCategories } from '../../../../context/Categories';

import { FiPlus } from "react-icons/fi";

export function ItemsColumn(addItem) {
  const { categories } = useCategories();

  return (
    [
      {
        title: 'Nome',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: 'Categoria',
        key: 'category',
        dataIndex: 'category',
        render: (category) => category.name,
        filters: categories.map(category => (
          {
            text: category.name,
            value: category.name
          }
        )),
        onFilter: (value, record) => record['category'].name.indexOf(value) === 0,
      },
      {
        title: 'Preço',
        key: 'price',
        dataIndex: 'price',
        render: (price) => (
          `R$${price.toFixed(2)}`
        ),
        ellipsis: true,
      },
      {
        key: 'add',
        render: (item) => (
          <FiPlus onClick={() => addItem(item)}/>
        ),
        fixed: 'right',
      },
    ]
  );
}