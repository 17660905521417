import React from 'react';

import { FiMoreHorizontal } from 'react-icons/fi';

import { Dropdown, Menu } from 'antd';

import UpdateSell from './UpdateSell';
import DeleteSell from './DeleteSell';

function SellManager({ sell, onDelete, onUpdate }) {

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <UpdateSell sell={sell} onUpdate={onUpdate} />
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <DeleteSell sell={sell} onDelete={onDelete} />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="topCenter">
      <FiMoreHorizontal />
    </Dropdown>
  );
}

export default SellManager;