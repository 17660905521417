import React from 'react';

import { FiMoreHorizontal } from 'react-icons/fi';

import { Dropdown, Menu } from 'antd';

import UpdateBird from './UpdateBird';
import DeleteBird from './DeleteBird';

function BirdManager({ bird, onDelete, onUpdate, categories }) {

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <UpdateBird bird={bird} onUpdate={onUpdate} categories={categories}/>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <DeleteBird bird={bird} onDelete={onDelete} />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="topCenter">
      <FiMoreHorizontal />
    </Dropdown>
  );
}

export default BirdManager;