import styled from 'styled-components';

export default [
	{
		"featureType": "administrative.land_parcel",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "landscape.man_made",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "poi",
		"elementType": "labels",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "labels",
		"stylers": [
			{
				"visibility": "simplified"
			},
			{
				"lightness": 20
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "geometry",
		"stylers": [
			{
				"hue": "#f49935"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "labels",
		"stylers": [
			{
				"visibility": "simplified"
			}
		]
	},
	{
		"featureType": "road.arterial",
		"elementType": "geometry",
		"stylers": [
			{
				"hue": "#fad959"
			}
		]
	},
	{
		"featureType": "road.arterial",
		"elementType": "labels",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "road.local",
		"elementType": "geometry",
		"stylers": [
			{
				"visibility": "simplified"
			}
		]
	},
	{
		"featureType": "road.local",
		"elementType": "labels",
		"stylers": [
			{
				"visibility": "simplified"
			}
		]
	},
	{
		"featureType": "transit",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "water",
		"elementType": "all",
		"stylers": [
			{
				"hue": "#a1cdfc"
			},
			{
				"saturation": 30
			},
			{
				"lightness": 49
			}
		]
	}
]

export const MapsContainer = styled.div`
	width: 100%;
	margin-bottom: 3rem;

  .locate {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    z-index: 10;
    width: 40px;
    cursor: pointer;
  }

	@media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

export const InfoWindowContent = styled.div`
	display: flex;
	flex-direction: column;

	h1 {
		font-size: 1.6rem;
		font-weight: 700;
		margin-bottom: 1.2rem;
	}

	span {
		font-size: 1.4rem;
		margin-bottom: 1rem;
	}
`;