import React, { createContext, useState, useContext } from 'react';

const SelectedContext = createContext();

function SelectedProvider({ children }) {
  const [selected, setSelected] = useState('');

  return (
    <SelectedContext.Provider value={{ selected, setSelected }}>
      {children}
    </SelectedContext.Provider>
  )
}

export default SelectedProvider;

export function useSelected() {
  const context = useContext(SelectedContext);
  const { selected, setSelected } = context;

  return { selected, setSelected };
}