import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { sellsApi } from '../../../../services';
import { useBirds } from '../../../../context/Birds';
import { useProducts } from '../../../../context/Products';
import { usePayments } from '../../../../context/Payments';

import { FiEdit, FiPlus, FiMinus, FiTrash2 } from 'react-icons/fi';

import { Empty, message, Select } from 'antd';

import { ItemsColumn } from '../ItemsColumn';
import Input from '../../../Input';

import {
  RegisterContainer, StyledSection, StyledModal, SellContainer, CartItemsContainer,
  CartItemCard, DecreaseButton, IncreaseButton, StyledTable, DropdownItem
} from '../styles';

function UpdateSell({ sell, onUpdate }) {
  const { birds } = useBirds();
  const { products } = useProducts();
  const { payments } = usePayments();
  const [allItems, setAllItems] = useState('');
  const id = sell.id;
  const [description, setDescription] = useState(sell.description);
  const [date, setDate] = useState(sell.date);
  const [prevDate, setPrevDate] = useState(moment(sell.date).format("YYYY-MM-DD"));
  const [paymentId, setPaymentId] = useState(sell.paymentId);
  const [items, setItems] = useState([]);
  const [discount, setDiscount] = useState(sell.discount);
  const [totalPrice, setTotalPrice] = useState(sell.price);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isChangingSell, setIsChangingSell] = useState(false);
  const { Option } = Select;

  const getItems = (thisItems) => {
    var newBirds = [...birds];
    var newProducts = [...products];

    for (var birdProp in newBirds) {
      for (var itemProp in thisItems) {
        if (thisItems[itemProp].birdId) {
          newBirds = newBirds.filter(bird => bird.birdId !== thisItems[itemProp].birdId);
        }
      }
    }
    for (var productProp in newProducts) {
      for (var itemProp in thisItems) {
        if (thisItems[itemProp].productId) {
          newProducts = newProducts.filter(bird => bird.productId !== thisItems[itemProp].productId);
        }
      }
    }
    setAllItems([...newBirds, ...newProducts]);
  }

  useEffect(() => {
    const getSell = async () => {
      setLoading(true);
      try {
        const response = await sellsApi.readOne(id);
        if (response.status === 200) {
          setItems(response.data.items);
          getItems(response.data.items);
        }
      } catch (error) {
        console.log(error);
      }
    }
    getSell();
    setLoading(false);
  }, [isChangingSell]);

  function handleSubmit(e) {
    e.preventDefault();

    if (items.length !== 0) {
      const cart = {
        items
      }

      onUpdate({
        id,
        paymentId,
        cart,
        date,
        description
      });

      setVisible(false);
    } else message.warning('Nenhum item adicionado à venda...');
  }

  function resetUpdating() {
    setIsChangingSell(true);
    setVisible(false);
    setTotalPrice(sell.price);
  }

  function compareIds(a, b) {
    return a.productId - b.productId || a.birdId - b.birdId;
  }

  function getDate(value) {
    setDate(moment(value).format());
    setPrevDate(value)
  }

  function getPayment(value) {
    setPaymentId(value);
  }

  function addItem(cartItem) {
    if (cartItem.birdId) {
      const { birdId, name, category, price } = cartItem;
      const amount = 1;
      const newCartItem = {
        birdId,
        name,
        category,
        price,
        amount
      };
      setItems([...items, newCartItem]);
      setAllItems(allItems.filter(item => item.birdId !== cartItem.birdId));
      setTotalPrice(totalPrice + cartItem.price);
    } else {
      const { productId, name, category, price } = cartItem;
      const amount = 1;
      const newCartItem = {
        productId,
        name,
        category,
        price,
        amount
      };
      setItems([...items, newCartItem]);
      setAllItems(allItems.filter(item => item.productId !== cartItem.productId));
      setTotalPrice(totalPrice + cartItem.price);
    }
  }

  function decreaseItemAmount(index) {
    const updItem = [...items];
    updItem[index].amount--;
    setItems(updItem);
    setTotalPrice(totalPrice - updItem[index].price);
  }

  function increaseItemAmount(index) {
    const updItem = [...items];
    updItem[index].amount++;
    setItems(updItem);
    setTotalPrice(totalPrice + updItem[index].price);
  }

  function removeCartItem(cartItem) {
    if (cartItem.birdId) {
      setItems(items.filter(item => item.birdId !== cartItem.birdId));
      setAllItems([...allItems, cartItem].sort(compareIds));
      setTotalPrice(totalPrice - (cartItem.amount * cartItem.price));
    } else {
      setItems(items.filter(item => item.productId !== cartItem.productId));
      setAllItems([...allItems, cartItem].sort(compareIds));
      setTotalPrice(totalPrice - (cartItem.amount * cartItem.price));
    }
  }

  return (
    <>
      <DropdownItem onClick={() => setVisible(true)}>
        <FiEdit /> Editar
      </DropdownItem>
      <StyledModal
        title="Editar venda"
        visible={visible}
        onCancel={() => resetUpdating()}
        footer={null}
      >
        <RegisterContainer>
          <SellContainer>
            <StyledTable scroll={{ x: true }} pagination={true} dataSource={allItems}
              columns={ItemsColumn(addItem)} loading={loading} rowKey="id" />

            <form onSubmit={handleSubmit}>
              <fieldset>
                <StyledSection>
                  <b>Descrição</b>
                  <Input
                    type="text"
                    name="description"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    required
                  />
                </StyledSection>

                <StyledSection>
                  <b>Data</b>
                  <Input required type="date" value={prevDate} onChange={e => getDate(e.target.value)} />
                </StyledSection>

                <StyledSection>
                  <b>Via de pagamento</b>
                  <Select size="large" placeholder="Selecione uma via" value={paymentId} onChange={getPayment}>
                    {payments.map(payment => (
                      <Option key={payment.id} value={payment.id}>{payment.name}</Option>
                    ))}
                  </Select>
                </StyledSection>
              </fieldset>

              <CartItemsContainer>
                <b>Carrinho</b>
                <div>
                  {items.length !== 0 ?
                    items.map((cartItem, index) => (
                      <CartItemCard key={cartItem.birdId || cartItem.productId}>
                        <b>{cartItem.name}</b>
                        <span>{cartItem.category.name}</span>
                        <div>
                          <DecreaseButton disabled={cartItem.amount === 1 ?? "true"} type="button" onClick={() => decreaseItemAmount(index)}>
                            <FiMinus />
                          </DecreaseButton>
                          <span>{cartItem.amount}</span>
                          <IncreaseButton type="button" onClick={() => increaseItemAmount(index)}>
                            <FiPlus />
                          </IncreaseButton>
                        </div>
                        <b>R${(cartItem.price * cartItem.amount).toFixed('2')}</b>
                        <FiTrash2 onClick={() => removeCartItem(cartItem)} />
                      </CartItemCard>
                    ))
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Nenhum item adicionado" />}
                </div>
              </CartItemsContainer>

              <StyledSection>
                <b>Desconto:</b>
                <Input
                  type="number"
                  name="discount"
                  value={discount}
                  onChange={e => setDiscount(e.target.value)}
                  required
                />
              </StyledSection>

              <StyledSection>
                <b>Valor total:</b>
                <b>R${totalPrice.toFixed('2')}</b>
              </StyledSection>

              <footer>
                <button type="submit" onClick={handleSubmit}>
                  Editar
                </button>
              </footer>
            </form>
          </SellContainer>
        </RegisterContainer>
      </StyledModal>
    </>
  );
}

export default UpdateSell;