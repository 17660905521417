import styled from 'styled-components';

import { Modal } from 'antd';

export const FormPage = styled.div`
    width: 100vw;
    max-width: 700px;
    height: 100vh;

  main {
    background-color: var(--color-content-background);
    box-shadow: 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.3);
    margin: 2rem 0;
    padding: 3rem 2rem;
    border-radius: 0.4rem;
  }

  fieldset {
    border: none;
  }

  footer {
    display: flex;
    flex-direction: column;
    border-top: 0.1rem solid var(--color-border);
    padding-top: 2rem;

    .terms {
        input {
          margin-right: 0.8rem
        }
        
        label {
          span {
            cursor: pointer;
            font-weight: bold;
            transition: opacity 0.2s;

            :hover {
              opacity: 0.8;
            }
          }
        }
      }
    
    button {
      width: 100%;
      height: 5.6rem;
      background: var(--color-primary);
      color: var(--color-highlight-text);
      border: 0;
      border-radius: 0.6rem;
      cursor: pointer;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      transition: opacity 0.2s;
      margin-top: 2rem;
      
      :hover {
        opacity: 0.8;
      }
    }
  }

  @media (min-width: 768px) {
    max-width: 100vw;

    main {
      max-width: 960px; 
      margin: 2rem auto;
      padding: 4rem 5rem;
    }

    footer {
      flex-direction: row;
      justify-content: space-between;

      button {
        width: 40%;
      }
    }
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  p {
    margin-bottom: 1rem;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  b {
    margin-bottom: 1rem;
  }
`;

export const CalculateFreight = styled.div`
  display: flex;
  align-items: flex-end;

  button {
    width: 40%;
    height: 4rem;
    background: var(--color-primary);
    color: var(--color-highlight-text);
    border: 0;
    border-radius: 0.6rem;
    cursor: pointer;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: opacity 0.2s;
    margin: 0 0 1rem 1.4rem;
    
    :hover {
      opacity: 0.8;
    }
  }
`;

export const StyledModal = styled(Modal)`
  min-width: 90vw;
  top: 2rem;

  @media (min-width: 780px) {
    min-width: 780px;
  }
`;

export const SelectBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;

  label {
    margin-bottom: 0.6rem;
  }
`;