import styled from 'styled-components';

import { Modal, Table } from 'antd';

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  
  a {
    display: flex;
    align-items: center;
    color: var(--color-primary-text);
  }

  svg {
    margin-right: 0.8rem;
    font-size: 1.8rem;
    color: var(--color-primary-text);
  }
`;

export const ModalContainer = styled.div`
  width: 100%;
  background-color: var(--color-content-background);
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  p {
    margin-bottom: 1rem;
  }
`;

export const SellsInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-border);
  padding: 1rem;
  margin-bottom: 1rem;

  span {
    margin-bottom: 1rem;
  }
`;

export const RegisterContainer = styled.div`
  width: 100%;
  background-color: var(--color-content-background);

  > button {
    width: 100%;
    height: 5.6rem;
    background: var(--color-primary);
    color: var(--color-highlight-text);
    border: 0;
    border-radius: 0.6rem;
    cursor: pointer;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: opacity 0.2s;
    margin-top: 2rem;
  }

  button:hover {
    opacity: 0.8;
  }

  @media (min-width: 768px) {
    padding: 1rem 1.6rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

export const ConfirmButton = styled.button`
  width: 10.6rem;
  height: 4.6rem;
  background: var(--color-primary);
  color: var(--color-highlight-text);
  border: 0;
  border-radius: 0.6rem;
  cursor: pointer;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: opacity 0.2s;
  
  :hover {
    opacity: 0.8;
  }
`;

export const CancelButton = styled.button`
  width: 10.6rem;
  height: 4.6rem;
  color: var(--color-primary);
  background: var(--color-content-background);
  border: 2px solid var(--color-primary);
  border-radius: 0.6rem;
  cursor: pointer;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: opacity 0.2s;
  margin-right: 2rem;

  :hover {
    opacity: 0.8;
  }
`;

export const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  b {
    margin-bottom: 0.6rem;
  }

  @media (min-width: 1080px) {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 1rem;
    align-items: center;

    b {
      margin-bottom: 0;

      :first-child {
        text-align: right;
      }
    }
  }
`;

export const StyledModal = styled(Modal)`
  min-width: 90vw;
  top: 0;

  @media (min-width: 1080px) {
    top: 10rem;
  }
`;

export const StyledTable = styled(Table)`
  width: 100%;
  margin-bottom: 2rem;

  tr > th, td {
    text-align: center;
  }

  td {
    max-width: 250px;
    min-width: 100px;
    word-break: break-word;

    svg {
      cursor: pointer;
      font-size: 2.4rem;

      :hover {
        opacity: 0.4;
        transition: 0.2s;
      }
    }
  }

  th {
    svg {
      font-size: 1.6rem;
    }
  }

  @media (min-width: 1080px) {
    margin-bottom: 0;
  }
`;

export const SellContainer = styled.div`
  width: 100%;
  background-color: var(--color-content-background);

  form {
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--color-border);
    padding-top: 2rem;
    overflow-y: auto;

    fieldset {
      margin-bottom: 1rem;
    }

    footer {
      button {
        width: 100%;
        background: var(--color-primary);
        height: 5.6rem;
        color: var(--color-highlight-text);
        border: 0;
        border-radius: 0.4rem;
        cursor: pointer;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        transition: opacity 0.2s;

        :hover {
          opacity: 0.8;
        }
      }
    }
  }

  @media (min-width: 1080px) {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 1rem;

    form {
      border-top: 0;
      border-left: 1px solid var(--color-border);
      padding-top: 0;
    
      footer {
        display: flex;
        justify-content: flex-end;

        button {
          width: 40%;
        }
      }
    }
  }
`;

export const CartItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  max-height: 720px;
  overflow-y: auto;

  > b {
    margin-bottom: 0.6rem;
  }

  @media (min-width: 1080px) {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 1rem;

    b {
      margin-bottom: 0;
      text-align: right;
    }
  }
`;

export const CartItemCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: center;
  border: 1px solid var(--color-border);
  border-radius: 0.4rem;
  padding: 1rem;
  margin-bottom: 1rem;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;

    span {
      margin: 0 1rem;
    }
  }

  > span {
      margin-bottom: 0.5rem;
    }

  button {
    width: 5rem;
    height: 3.2rem;
    color: var(--color-highlight-text);
    border: 0;
    border-radius: 0.4rem;
    cursor: pointer;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: opacity 0.2s;
  }

  > svg {
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2.0rem;
    transition: color 0.2s;

    :hover {
      color: #ff6961;
    }
  }

  @media (min-width: 1280px) {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 1fr 1fr;
    gap: 1rem;
    height: fit-content;
    justify-items: center;

    > div {
      margin-bottom: 0;
    }

    > span {
      margin-bottom: 0;
    }

    > svg {
      position: relative;
      top: auto;
      right: auto;
    }
  }
`;

export const DecreaseButton = styled.button`
  background: #ff6961;
`;

export const IncreaseButton = styled.button`
  background: var(--color-primary);
`;