import styled from 'styled-components';

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  
  a {
    display: flex;
    align-items: center;
    color: var(--color-primary-text);
  }

  svg {
    margin-right: 0.8rem;
    font-size: 1.8rem;
    color: var(--color-primary-text);
  }
`;

export const ModalContainer = styled.div`
  width: 100%;
  background-color: var(--color-content-background);
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  p {
    margin-bottom: 0.6rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

export const ConfirmButton = styled.button`
  width: 10.6rem;
  height: 4.6rem;
  background: var(--color-primary);
  color: var(--color-highlight-text);
  border: 0;
  border-radius: 0.6rem;
  cursor: pointer;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: opacity 0.2s;
  
  :hover {
    opacity: 0.8;
  }
`;

export const CancelButton = styled.button`
  width: 10.6rem;
  height: 4.6rem;
  color: var(--color-primary);
  background: var(--color-content-background);
  border: 2px solid var(--color-primary);
  border-radius: 0.6rem;
  cursor: pointer;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: opacity 0.2s;
  margin-right: 2rem;

  :hover {
    opacity: 0.8;
  }
`;

export const RegisterButton = styled.button`
  width: 100%;
  height: 5.6rem;
  background: var(--color-primary);
  color: var(--color-highlight-text);
  border: 0;
  border-radius: 0.6rem;
  cursor: pointer;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: opacity 0.2s;
  margin-top: 2rem;

  :hover {
    opacity: 0.8;
  }
`;

export const CategoryInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;

  label {
    margin-bottom: 0.4rem;
  }
`;