import React, { createContext, useState, useContext, useEffect } from 'react';

import { paymentsApi } from '../services';

const PaymentsContext = createContext();

function PaymentsProvider({ children }) {
  const [payments, setPayments] = useState([]);
  const [newPayment, setNewPayment] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPayments = async () => {
      setLoading(true);
      try {
        const response = await paymentsApi.read();
        if (response.status === 200) {
          setPayments(response.data.sort((a, b) => a.name.localeCompare(b.name)));
        }
      } catch (error) {
        setPayments([]);
      }
      setLoading(false);
    }
    getPayments();
  }, [newPayment]);

  return (
    <PaymentsContext.Provider value={{ 
      payments, 
      setPayments, 
      newPayment, 
      setNewPayment, 
      loading, 
      setLoading 
    }}>
      {children}
    </PaymentsContext.Provider>
  )
}

export default PaymentsProvider;

export function usePayments() {
  const context = useContext(PaymentsContext);
  const { payments, setPayments, newPayment, setNewPayment, loading, setLoading } = context;

  return { payments, setPayments, newPayment, setNewPayment, loading, setLoading };
}