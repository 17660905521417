import React from 'react';

import { RadioBlock } from './styles';

function Radio({ id, name, label, value, required, ...rest }) {
  return(
    <RadioBlock>
      <input type="radio" id={id} name={name} value={value} required={required} {...rest} />
      <label htmlFor={id} >{label}</label>
    </RadioBlock>
  );
}

export default Radio;