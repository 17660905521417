import React, { useState, useEffect } from 'react';

import { birdsApi } from '../../../../services';
import { useCategories } from '../../../../context/Categories';
import { useBirds } from '../../../../context/Birds';

import { message } from 'antd';

import CreateBird from '../../../../components/Managers/BirdManager/CreateBird';
import BirdManager from '../../../../components/Managers/BirdManager';

import { AddButton, StyledTable } from '../../styles';

function Birds() {
  const { categories } = useCategories();
  const { birds, loading, setAux } = useBirds();
  const [newBird, setNewBird] = useState({});
  const [birdsCategories, setBirdsCategories] = useState([]);

  useEffect(() => {
    setAux(newBird);
  }, [newBird]);

  useEffect(() => {
    setBirdsCategories(categories.filter(category => category.type === 'bird'));
  }, [categories]);
  
  async function createBird(values) {
    const { name, price, categoryId } = values;
    try {
      message.loading({ 
        key: "creating",
        content: "Cadastrando ave...",
        duration: 9999
      });
      const response = await birdsApi.create({
        name,
        categoryId,
        price
      });
      if (response.status === 200) {
        setNewBird(response.data);
        message.destroy("creating");
        message.success("Aves cadastrado com sucesso!");
      }
    } catch (error) {
      message.destroy("creating");
      message.error("Erro ao cadastrar a ave, tente novamente...");
    }
  }

  async function updateBird(values) {
    const { id, name, categoryId, price } = values;
    try {
      message.loading({ 
        key: "updating",
        content: "Editando ave...",
        duration: 9999
      });
      const response = await birdsApi.update({
        id,
        name,
        categoryId,
        price
      });
      if (response.status === 200) {
        setNewBird(response.data);
        message.destroy("updating");
        message.success("Ave editado com sucesso!");
      }
    } catch (error) {
      message.destroy("updating");
      message.error("Erro ao editar a ave, tente novamente...");
    }
  }
  
  async function deleteBird(id) {
    try {
      message.loading({ 
        key: "deleting",
        content: "Removendo ave...",
        duration: 9999
      });
      const response = await birdsApi.delete(id);
      if (response.status === 200) {
        setNewBird(response.data);
        message.destroy("deleting");
        message.success("Ave removida com sucesso!");
      }
    } catch (error) {
      message.destroy("deleting");
      message.error("Erro ao remover a ave, tente novamente...");
    }
  }

  const columns = [
    {
      title: 'Raça',
      key: 'name',
      dataIndex: 'name',
      ellipsis: 'true'
    },
    {
      title: 'Categoria',
      key: 'category',
      dataIndex: 'category',
      render: (category) => category.name,
      filters: birdsCategories.map(category => (
        {
          text: category.name,
          value: category.name
        }
      )),
      onFilter: (value, record) => record['category'].name.indexOf(value) === 0,
    },
    {
      title: 'Preço',
      key: 'price',
      dataIndex: 'price',
      render: (price) => (
        `R$${price.toFixed(2)}`
      ),
      ellipsis: 'true'
    },
    {
      title: 'Configurações',
      key: 'config',
      ellipsis: 'true',
      render: (bird) => (
        
        <BirdManager 
          bird={bird}
          onDelete={deleteBird}
          onUpdate={updateBird}
          categories={birdsCategories}
        />
      ),
    },
  ];

  return (
    <>
      <AddButton>
          <CreateBird onCreate={createBird} categories={birdsCategories}/>
      </AddButton>
      
      <StyledTable scroll={{ x: true }} pagination={true} loading={loading}
        columns={columns} dataSource={birds} rowKey="birdId" />
    </>
  );
}

export default Birds;
