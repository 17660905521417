import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { authApi } from '../../services';
import { login, isAuthenticated } from '../../services/auth';
import { useUser } from '../../context/User';

import { message } from 'antd';
import Input from '../../components/Input';

import { LoginPage, LoginContainer } from './styles';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setUser } = useUser();
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      message.loading({
        key: "logging",
        content: "Logando...",
        duration: 9999
      });
      const response = await authApi.login({
        email,
        password
      });
      login(response.data.token);
      if (response.status === 200) {
        setUser(response.data.admin);
        history.push('/system');
        message.destroy("logging");
      }
    } catch (error) {
      message.destroy("logging");
      message.error("Erro ao logar, verifique se o e-mail e senha estão corretos e tente novamente", 3);
    }
  }

  return (
    <LoginPage>
      {isAuthenticated() ? <Redirect to="/system" /> : null}
      <LoginContainer>
        <form onSubmit={handleSubmit}>
          <h1>Aves Mansas</h1>
          <Input
            type="email"
            name="email"
            label="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
          <Input
            type="password"
            name="password"
            label="Senha"
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
          />
          <button type="submit">Entrar</button>
        </form>
      </LoginContainer>
    </LoginPage>
  );
}

export default Login;