import React, { useState } from 'react';

import { FiPlus } from 'react-icons/fi';

import { Modal, Select } from 'antd';

import Input from '../../../Input';

import { ModalContainer, CategoryInput, RegisterButton } from '../../styles';

function CreateBird({ onCreate, categories }) {
  const [name, setName] = useState('');
  const [categoryId, setCategoryId] = useState(null);
  const [price, setPrice] = useState('');
  const [visible, setVisible] = useState(false);
  const { Option } = Select;

  async function handleCreate(e) {
    e.preventDefault();

    onCreate({
      name,
      categoryId,
      price
    });

    setVisible(false);
    setName('');
    setCategoryId(null);
    setPrice('');
  }

  async function handleChange(value) {
    await setCategoryId(value);
  }
  return (
    <>
      <button type="button" onClick={() => setVisible(true)} title="Cadastrar">
        <FiPlus />
      </button>
      <Modal
        title="Adicionar Ave"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <ModalContainer>
          <form onSubmit={handleCreate}>
            <Input
              type="text"
              name="raca"
              label="Raça"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
            <CategoryInput>
              <label>Categoria</label>
              <Select
                value={categoryId}
                style={{ width: "100%" }}
                size="large"
                placeholder="Selecione uma categoria"
                onChange={handleChange}>
                {
                  categories.map(category => (
                    <Option value={category.id}>{category.name}</Option>
                  ))
                }
              </Select>
            </CategoryInput>
            <Input
              type="text"
              name="preco"
              label="Preço"
              prefix="R$"
              value={price}
              onChange={e => setPrice(e.target.value)}
              required
            />
            <RegisterButton type="submit">Adicionar</RegisterButton>
          </form>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default CreateBird;