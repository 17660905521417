import styled from 'styled-components';

export const TitleContainer = styled.legend`
  width: 100%;
  border-bottom: 0.1rem solid var(--color-border);
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  
  span {
    font-size: 2.4rem;
    font-weight: 700;
    color: var(--color-title-text);
  }
`;