import api from './api';

const clientsApi = {
  create: (data) => api.post('/register', data),
  read: () => api.get('/allClients'),
  update: (data) => api.put('/update', data),
  delete: (id) => api.delete(`/delete/${id}`)
};

export default clientsApi;
