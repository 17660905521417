import React, { useState } from 'react';

import { FiTrash2 } from 'react-icons/fi';

import { Modal } from 'antd';

import { CancelButton, ConfirmButton, ModalContainer, ButtonsContainer, 
  DropdownItem } from '../styles';

function DeleteSell({ sell, onDelete }) {
  const [visible, setVisible] = useState(false);

  function handleDelete() {
    onDelete(sell.id);
    setVisible(false);
  }

  return (
    <>
      <DropdownItem onClick={() => setVisible(true)}>
        <FiTrash2 /> Remover
      </DropdownItem>
      <Modal
        title="Remover venda"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <ModalContainer>
            <span>Deseja remover essa venda?</span>
            <ButtonsContainer>
              <CancelButton type="reset" onClick={() => setVisible(false)}>Não</CancelButton>
              <ConfirmButton type="submit" onClick={handleDelete}>Sim</ConfirmButton>
            </ButtonsContainer>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default DeleteSell;