import React, { useState } from 'react';

import { FiEdit } from 'react-icons/fi';

import { Modal, Select } from 'antd';

import Input from '../../../Input';

import { DropdownItem, ModalContainer, ButtonsContainer, CancelButton, ConfirmButton, CategoryInput } from '../../styles';

function UpdateCategory({ category, onUpdate }) {
  const id = category.id;
  const [name, setName] = useState(category.name);
  const [type, setType] = useState(category.type);
  const [visible, setVisible] = useState(false);
  const { Option } = Select;

  function handleUpdate(e) {
    e.preventDefault();

    onUpdate({
      id,
      name,
      type
    });

    setVisible(false);
  }

  async function handleChange(value) {
    await setType(value);
  }

  return (
    <>
      <DropdownItem onClick={() => setVisible(true)}>
        <FiEdit /> Editar
      </DropdownItem>
      <Modal
        title="Editar categoria"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <ModalContainer>
          <form onSubmit={handleUpdate}>
            <Input
              type="text"
              name="name"
              label="Nome"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
            <CategoryInput>
              <label >Categoria</label>
              <Select 
                value={type} 
                defaultValue={type} 
                size="large"
                style={{ width: "100%" }} 
                onChange={handleChange}
              >
                <Option value="bird">Ave</Option>
                <Option value="product">Produto</Option>
              </Select>
            </CategoryInput>
            <ButtonsContainer>
              <CancelButton type="reset" onClick={() => setVisible(false)}>Cancelar</CancelButton>
              <ConfirmButton type="submit">Editar</ConfirmButton>
            </ButtonsContainer>
          </form>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default UpdateCategory;