import styled from 'styled-components';

export const ConfirmPage = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-highlight-text);
  background-color: var(--color-primary);
  text-align: center;

  span {
    font-size: 3.2rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1.8rem;
    margin-bottom: 0;

    :last-child {
      margin-top: 1rem;
    }
  }
`;