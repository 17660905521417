import React, { createContext, useState, useContext, useEffect } from 'react';

import { categoriesApi } from '../services';

// const loadState = () => {
//   try {
//     const serializedState = localStorage.getItem("categories");
//     if (serializedState === null) {
//       return [];
//     }
//     return JSON.parse(serializedState);
//   } catch (e) {
//     return [];
//   }
// };

// const saveState = state => {
//   try {
//     const serializedState = JSON.stringify(state);
//     localStorage.setItem("categories", serializedState);
//   } catch (e) {
//     // Ignore write errors;
//   }
// };

const CategoriesContext = createContext();

function CategoriesProvider({ children }) {
  const [categories, setCategories] = useState([]);
  const [aux, setAux] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getCategories = async () => {
      setLoading(true);
      try {
        const response = await categoriesApi.read();
        if (response.status === 200) {
          setCategories(response.data.sort((a, b) => a.name.localeCompare(b.name)));
        }
      } catch (error) {
        setCategories([]);
      }
      setLoading(false);
    }
    getCategories();
    // saveState(categories);
  }, [aux]);

  return (
    <CategoriesContext.Provider value={{ 
      categories, 
      setCategories,
      aux, 
      setAux, 
      loading, 
      setLoading
      }}>
      {children}
    </CategoriesContext.Provider>
  )
}

export default CategoriesProvider;

export function useCategories() {
  const context = useContext(CategoriesContext);
  const { categories, setCategories, aux, setAux, loading, setLoading } = context;

  return { categories, setCategories, aux, setAux, loading, setLoading };
}