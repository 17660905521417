import React from 'react';

import { Tabs } from 'antd';

import Products from './Products';
import Categories from './Categories';
import Birds from './Birds';
import Graphics from './Graphics';
import Payments from './Payments';
import Sells from './Sells';

import { SectionContainer } from '../styles';

function Management() {
  const { TabPane } = Tabs;

  return (
    <SectionContainer>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Vendas" key="1">
          <Sells />
        </TabPane>
        <TabPane tab="Relatórios" key="2">
          <Graphics />
        </TabPane>
        <TabPane tab="Aves" key="3">
          <Birds />
        </TabPane>
        <TabPane tab="Produtos" key="4">
          <Products />
        </TabPane>
        <TabPane tab="Categorias" key="5">
          <Categories />
        </TabPane>
        <TabPane tab="Vias de pagamento" key="6">
          <Payments />
        </TabPane>
      </Tabs>
    </SectionContainer>
  );
}

export default Management;
