import React, { useState } from 'react';

import { FiEdit } from 'react-icons/fi';

import { Modal } from 'antd';

import Input from '../../../Input';

import { DropdownItem, ModalContainer, ButtonsContainer, CancelButton, ConfirmButton } from '../../styles';

function UpdatePayment({ payment, onUpdate }) {
  const id = payment.id;
  const [name, setName] = useState(payment.name);
  const [visible, setVisible] = useState(false);

  function handleUpdate(e) {
    e.preventDefault();

    onUpdate({
      id,
      name
    });

    setVisible(false);
  }

  return (
    <>
      <DropdownItem onClick={() => setVisible(true)}>
        <FiEdit /> Editar
      </DropdownItem>
      <Modal
        title="Editar via de pagamento"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <ModalContainer>
          <form onSubmit={handleUpdate}>
            <Input
              type="text"
              name="name"
              label="Nome"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
            <ButtonsContainer>
              <CancelButton type="reset" onClick={() => setVisible(false)}>Cancelar</CancelButton>
              <ConfirmButton type="submit">Editar</ConfirmButton>
            </ButtonsContainer>
          </form>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default UpdatePayment;