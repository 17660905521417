import styled from 'styled-components';

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  background-color: var(--color-primary);
  padding: 3rem;
  box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.3);
  color: var(--color-highlight-text);
  text-align: center;

  h1 {
    font-size: 3rem;
    margin-bottom: 1.2rem;
    font-weight: 700;
    color: var(--color-highlight-text);
  }

  p {
    margin-bottom: 0;
  }
`;