import styled from 'styled-components';

import { Dropdown } from 'antd';

export const Nav = styled.nav`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  align-items: center;
  background-color: var(--color-primary);
  padding: 3rem 4rem;
  box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.3);
  color: var(--color-highlight-text);

  h1 {
    cursor: pointer;
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 0;
    color: var(--color-highlight-text);
  }

  svg {
    cursor: pointer;
    font-size: 2.4rem;

    :hover {
      opacity: 0.8;
      transition: 0.2s;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3rem;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const NavMenu = styled.div`
  display: flex;
  justify-content: center;

  a {
    :first-child {
      margin-right: 1rem;
    }
    :last-child {
      margin-left: 1rem;
    }

    display: flex;
    align-items: center;
    color: var(--color-highlight-text);
    font-size: 2.4rem;
    font-weight: 700;
    padding: 0 1rem;

    svg {
      color: var(--color-highlight-text);
      font-size: 2.4rem;
      font-weight: 700;
      margin-right: 1.2rem;
    }
    :hover {
      opacity: 0.8;
      transition: 0.2s;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const RightMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    margin-right: 1.2rem;
    font-size: 2rem;
    font-weight: 700;
    cursor: default;
  }

  svg {
    font-size: 2.4rem;
    font-weight: 700;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledMenu = styled(Dropdown)`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  
  a {
    display: flex;
    align-items: center;
    color: var(--color-primary-text);
  }

  svg {
    margin-right: 0.8rem;
    font-size: 1.8rem;
    color: var(--color-primary-text);
  }
`;
