import React from 'react';

import { FiMoreHorizontal } from 'react-icons/fi';

import { Dropdown, Menu } from 'antd';

import UpdatePayment from './UpdatePayment';
import DeletePayment from './DeletePayment';

function PaymentManager({ payment, onDelete, onUpdate }) {

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <UpdatePayment payment={payment} onUpdate={onUpdate} />
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <DeletePayment payment={payment} onDelete={onDelete} />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="topCenter">
      <FiMoreHorizontal />
    </Dropdown>
  );
}

export default PaymentManager;