import React, { useCallback, useRef } from 'react';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow
} from '@react-google-maps/api';

import { useCenter } from '../../context/Center';
import { useSelected } from '../../context/Selected';

import { FiHome } from "react-icons/fi";

import mapStyles, { InfoWindowContent } from "./styles";

import { MapsContainer } from './styles';

const libraries = ["places"];

const mapContainerStyle = {
  width: "100%",
  height: "600px",
};

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};

function Maps({ clients }) {
  const { center, setCenter } = useCenter();
  const { selected, setSelected } = useSelected();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    region: 'br',
    language: 'pt-Br',
    libraries
  });

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(15);
  }, []);

  if (loadError) return "Erro ao carregar o mapa...";
  if (!isLoaded) return "Carregando mapa...";

  function Locate({ panTo }) {
    return <button className="locate" onClick={() => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position);
          panTo({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        }, () => null);
    }}>
      <FiHome style={{ color: 'var(--color-primary)' }} size='40' />
    </button>
  }

  function markerColor(color) {
    return `/assets/${color}.png`;
  }

  return (
    <MapsContainer>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={15}
        center={center}
        options={options}
        onLoad={onMapLoad}
      >
        {clients.map(client => (
          <Marker
            key={client.id}
            position={{ lat: client.lat, lng: client.lon }}
            icon={{
              url: markerColor(client.color),
              scaledSize: new window.google.maps.Size(28, 28),
            }}
            onClick={() => {
              //quando clicar em um marcador
              setSelected(client);
              setCenter({
                lat: client.lat,
                lng: client.lon
              })
            }}
          />
        ))}

        {selected ? (
          <InfoWindow
            position={center}
            onCloseClick={() => {
              setSelected(null);
            }}
          >
            <InfoWindowContent>
              <h1>{selected.name}</h1>
              <span>CEP: {selected.postalCode}</span>
              <span>WhatsApp: {selected.whatsApp}</span>
              <span>Frete: R${selected.freight}</span>
            </InfoWindowContent>
          </InfoWindow>
        ) : null}

        <Locate panTo={panTo} />

      </GoogleMap>
    </MapsContainer>);
}

export default Maps;