import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { sellsApi } from '../../../../services';
import { usePayments } from '../../../../context/Payments';

import { message } from 'antd';

import CreateSell from '../../../../components/Managers/SellManager/CreateSell';
import GetSell from '../../../../components/Managers/SellManager/GetSell';
import SellManager from '../../../../components/Managers/SellManager';

import { AddButton, StyledTable } from '../../styles';

function Sells() {
  const { payments } = usePayments();
  const [sells, setSells] = useState([]);
  const [newSell, setNewSell] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getSells = async () => {
      setLoading(true);
      try {
        const response = await sellsApi.readAll();
        if (response.status === 200) {
          setSells(response.data.sort((a, b) => new Date(moment(b.date).format('YYYY-MM-DD')) - new Date(moment(a.date).format('YYYY-MM-DD'))));
        }
      } catch (error) {
      }
      setLoading(false);
    }
    getSells();
  }, [newSell]);

  async function createSell(values) {
    const { cart, description, paymentId, date, discount } = values;
    try {
      message.loading({
        key: "creating",
        content: "Cadastrando venda...",
        duration: 9999
      });
      const response = await sellsApi.create({
        cart,
        description,
        paymentId,
        date,
        discount
      });
      if (response.status === 200) {
        setNewSell(response.data);
        message.destroy("creating");
        message.success("Venda cadastrada com sucesso!");
      }
    } catch (error) {
      console.log(error)
      message.destroy("creating");
      message.error("Erro ao cadastrar a venda, tente novamente...");
    }
  }

  async function updateSell(values) {
    const { id, paymentId, cart, date, description, discount } = values;
    try {
      message.loading({
        key: "updating",
        content: "Editando venda...",
        duration: 9999
      });
      const response = await sellsApi.update({
        id,
        paymentId,
        cart,
        date,
        description,
        discount
      });
      if (response.status === 200) {
        setNewSell(response.data);
        message.destroy("updating");
        message.success("Venda editada com sucesso!");
      }
    } catch (error) {
      message.destroy("updating");
      message.error("Erro ao editar a venda, tente novamente...");
    }
  }

  async function deleteSell(id) {
    try {
      message.loading({
        key: "deleting",
        content: "Removendo venda...",
        duration: 9999
      });
      const response = await sellsApi.delete(id);
      if (response.status === 200) {
        setNewSell(response.data);
        message.destroy("deleting");
        message.success("Venda removida com sucesso!");
      }
    } catch (error) {
      message.destroy("deleting");
      message.error("Erro ao remover venda, tente novamente...");
    }
  }

  const columns = [
    {
      title: 'Descrição',
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: 'Data',
      key: 'date',
      dataIndex: 'date',
      render: (date) => (
        moment(date).format('DD/MM/YYYY')
      ),
      ellipsis: 'true'
    },
    {
      title: 'Preço',
      key: 'price',
      dataIndex: 'price',
      render: (price) => (
        `R$${price.toFixed(2)}`
      ),
      ellipsis: true,
    },
    {
      title: 'Desconto',
      key: 'discount',
      dataIndex: 'discount',
      render: (discount) => discount ? (
        `R$${discount.toFixed(2)}`
      ) : (
        'R$0.00'
      ),
      ellipsis: true,
    },
    {
      title: 'Via de pagamento',
      key: 'payment',
      dataIndex: 'payment',
      render: (payment) => payment.name,
      filters: payments.map(payment => (
        {
          text: payment.name,
          value: payment.name
        }
      )),
      onFilter: (value, record) => record['payment'].name.indexOf(value) === 0,
    },
    {
      title: 'Visualizar',
      key: 'view',
      render: (sell) => (
        <GetSell
          sellId={sell.id}
        />
      ),
    },
    {
      title: 'Configurações',
      key: 'config',
      render: (sell) => (
        <SellManager
          sell={sell}
          onDelete={deleteSell}
          onUpdate={updateSell}
        />
      ),
    },
  ];

  return (
    <>
      <AddButton>
        <CreateSell onCreate={createSell} />
      </AddButton>
      <StyledTable scroll={{ x: true }} pagination={true} loading={loading}
        columns={columns} dataSource={sells} rowKey="id" />
    </>
  );
}

export default Sells;
