import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useBirds } from '../../../../context/Birds';
import { useProducts } from '../../../../context/Products';
import { usePayments } from '../../../../context/Payments';

import { Select, Empty, message } from 'antd';

import { FiPlus, FiMinus, FiTrash2 } from 'react-icons/fi';
import { ItemsColumn } from '../ItemsColumn';
import Input from '../../../Input';

import {
  RegisterContainer, StyledSection, StyledModal, SellContainer, CartItemsContainer,
  CartItemCard, DecreaseButton, IncreaseButton, StyledTable
} from '../styles';

function CreateSell({ onCreate }) {
  const { birds } = useBirds();
  const { products } = useProducts();
  const { payments } = usePayments();
  const [allItems, setAllItems] = useState('');
  const [paymentId, setPaymentId] = useState(null);
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [items, setItems] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const { Option } = Select;

  useEffect(() => {
    setLoading(true);
    const getAllItems = () => {
      setAllItems([...birds, ...products]);
    }
    getAllItems();
    setLoading(false);
  }, [birds, products]);

  async function handleSubmit(e) {
    e.preventDefault();
    console.log(date)

    if (items.length !== 0) {
      const cart = await {
        items
      }

      onCreate({
        cart,
        description,
        paymentId,
        date,
        discount
      });

      setVisible(false);
    } else message.warning('Nenhum item adicionado à venda...');
  }

  function compareIds(a, b) {
    return a.id - b.id;
  }

  function getDate(value) {
    console.log(new Date(value).toISOString())
    setDate(moment(value).format());
  }

  function getPayment(value) {
    setPaymentId(value);
  }

  function addItem(cartItem) {
    if (cartItem.birdId) {
      const { birdId, name, category, price } = cartItem;
      const amount = 1;
      const newCartItem = {
        birdId,
        name,
        category,
        price,
        amount
      };
      setItems([...items, newCartItem]);
      setAllItems(allItems.filter(item => item.birdId !== cartItem.birdId));
      setTotalPrice(totalPrice + cartItem.price);
    } else {
      const { productId, name, category, price } = cartItem;
      const amount = 1;
      const newCartItem = {
        productId,
        name,
        category,
        price,
        amount
      };
      setItems([...items, newCartItem]);
      setAllItems(allItems.filter(item => item.productId !== cartItem.productId));
      setTotalPrice(totalPrice + cartItem.price);
    }
  }

  function decreaseItemAmount(index) {
    const updItem = [...items];
    updItem[index].amount--;
    setItems(updItem);
    setTotalPrice(totalPrice - updItem[index].price);
  }

  function increaseItemAmount(index) {
    const updItem = [...items];
    updItem[index].amount++;
    setItems(updItem);
    setTotalPrice(totalPrice + updItem[index].price);
  }

  function removeCartItem(cartItem) {
    if (cartItem.birdId) {
      setItems(items.filter(item => item.birdId !== cartItem.birdId));
      setAllItems([...allItems, cartItem].sort(compareIds));
      setTotalPrice(totalPrice - (cartItem.amount * cartItem.price));
    } else {
      setItems(items.filter(item => item.productId !== cartItem.productId));
      setAllItems([...allItems, cartItem].sort(compareIds));
      setTotalPrice(totalPrice - (cartItem.amount * cartItem.price));
    }
  }

  return (
    <>
      <button type="button" onClick={() => setVisible(true)} title="Cadastrar">
        <FiPlus />
      </button>
      <StyledModal
        title="Cadastrar venda"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <RegisterContainer>
          <SellContainer>
            <StyledTable scroll={{ x: true }} pagination={true} dataSource={allItems}
              columns={ItemsColumn(addItem)} loading={loading} rowKey="id" />

            <form onSubmit={handleSubmit}>
              <fieldset>
                <StyledSection>
                  <b>Descrição</b>
                  <Input
                    type="text"
                    name="description"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    required
                  />
                </StyledSection>

                <StyledSection>
                  <b>Data</b>
                  <Input
                    type="date"
                    name="date"
                    onChange={e => getDate(e.target.value)}
                    required
                  />
                </StyledSection>

                <StyledSection>
                  <b>Via de pagamento</b>
                  <Select
                    size="large"
                    placeholder="Selecione uma via"
                    value={paymentId} onChange={getPayment}
                  >
                    {payments.map(payment => (
                      <Option key={payment.id} value={payment.id}>{payment.name}</Option>
                    ))}
                  </Select>
                </StyledSection>
              </fieldset>

              <CartItemsContainer>
                <b>Carrinho</b>
                <div>
                  {items.length !== 0 ?
                    items.map((cartItem, index) => (
                      <CartItemCard key={cartItem.birdId || cartItem.productId}>
                        <b>{cartItem.name}</b>
                        <span>{cartItem.category.name}</span>
                        <div>
                          <DecreaseButton disabled={cartItem.amount === 1 ?? "true"} type="button" onClick={() => decreaseItemAmount(index)}>
                            <FiMinus />
                          </DecreaseButton>
                          <span>{cartItem.amount}</span>
                          <IncreaseButton type="button" onClick={() => increaseItemAmount(index)}>
                            <FiPlus />
                          </IncreaseButton>
                        </div>
                        <b>R${(cartItem.price * cartItem.amount).toFixed('2')}</b>
                        <FiTrash2 onClick={() => removeCartItem(cartItem)} />
                      </CartItemCard>
                    ))
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Nenhum item adicionado" />}
                </div>
              </CartItemsContainer>

              <StyledSection>
                <b>Desconto:</b>
                <Input
                  type="number"
                  name="discount"
                  onChange={e => setDiscount(e.target.value)}
                  required
                />
              </StyledSection>

              <StyledSection>
                <b>Valor total:</b>
                <b>R${(totalPrice.toFixed('2') - discount)}</b>
              </StyledSection>

              <footer>
                <button type="submit" onClick={handleSubmit}>
                  Cadastrar
                </button>
              </footer>
            </form>
          </SellContainer>
        </RegisterContainer>
      </StyledModal>
    </>
  );
}

export default CreateSell;