import React, { useEffect, useState } from 'react';

import { sellsApi } from '../../../../services';

import { FiSearch } from 'react-icons/fi';
import { Modal, Skeleton } from 'antd';

import { ModalContainer, ButtonsContainer, CancelButton, SellsInfoContainer } from '../styles';

function GetSell({ sellId }) {
  const [sell, setSell] = useState({});
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const viewSell = async () => {
      setLoading(true);
      try {
        const response = await sellsApi.readOne(sellId);
        if (response.status === 200) {
          setSell(response.data);
          setItems(response.data.items);
        }
      } catch (error) {
        console.log(error);
      }
    }
    viewSell();
    setLoading(false);
  }, []);

  return (
    <>
      <FiSearch onClick={() => setVisible(true)} />
      <Modal
        title="Visualizar venda"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <ModalContainer>
          <Skeleton loading={loading} active>
            {items.map(item => (
              <SellsInfoContainer key={item.productId || item.birdId}>
                <span>Nome: {item.name}</span>
                <span>Categoria: {item.category.name}</span>
                <span>Quantidade: {item.amount}</span>
                <span>Preço: R${item.price.toFixed(2)}</span>
              </SellsInfoContainer>
            ))}
            <SellsInfoContainer>
              {sell.discount ? (
                <span>Desconto: R${sell.discount.toFixed(2)}</span>
              ) : (
                null
              )}
              {sell.priceFinal ? (
                <span>Preço final: R${sell.priceFinal.toFixed(2)}</span>
              ) : (
                null
              )}
            </SellsInfoContainer>
          </Skeleton>
          <ButtonsContainer>
            <CancelButton type="button" onClick={() => setVisible(false)}>Fechar</CancelButton>
          </ButtonsContainer>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default GetSell;