import React from 'react';

import { FiMoreHorizontal } from 'react-icons/fi';

import { Dropdown, Menu } from 'antd';

import UpdateProduct from './UpdateProduct';
import DeleteProduct from './DeleteProduct';

function ProductManager({ product, onDelete, onUpdate, categories }) {

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <UpdateProduct product={product} onUpdate={onUpdate} categories={categories} />
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <DeleteProduct product={product} onDelete={onDelete} />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="topCenter">
      <FiMoreHorizontal />
    </Dropdown>
  );
}

export default ProductManager;