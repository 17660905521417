import React, { useState } from 'react';

import { FiEdit } from 'react-icons/fi';

import { Modal } from 'antd';

import Input from '../../../Input';

import { DropdownItem, ModalContainer, ButtonsContainer, CancelButton, 
  ConfirmButton } from '../../styles';

function UpdateAdmin({ admin, onUpdate }) {
  const id = admin.id
  const [name, setName] = useState(admin.name);
  const [email, setEmail] = useState(admin.email);
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);

  function handleUpdate(e) {
    e.preventDefault();

    onUpdate({
      id,
      name,
      email,
      password
    });

    setPassword('');

    setVisible(false);
  }

  return (
    <>
      <DropdownItem onClick={() => setVisible(true)}>
        <FiEdit /> Editar
      </DropdownItem>
      <Modal
        title="Editar admin"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <ModalContainer>
          <form onSubmit={handleUpdate}>
            <Input
              type="text"
              name="name"
              label="Nome"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
            <Input
              type="email"
              name="email"
              label="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
            <Input
              type="password"
              name="password"
              label="Senha"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
            <ButtonsContainer>
              <CancelButton type="reset" onClick={() => setVisible(false)}>Cancelar</CancelButton>
              <ConfirmButton type="submit">Editar</ConfirmButton>
            </ButtonsContainer>
          </form>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default UpdateAdmin;