import styled from 'styled-components';

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 4rem 1rem;
  font-size: 1.2rem;

  a {
    color: #12264a;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    padding: 0 3rem 1rem;
  }
`;