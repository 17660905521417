import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { logout } from '../../../services/auth';
import { adminsApi } from '../../../services';
import { useUser } from '../../../context/User';

import { message } from 'antd';

import CreateAdmin from '../../../components/Managers/AdminManager/CreateAdmin';
import AdminManager from '../../../components/Managers/AdminManager';

import { AddButton, SectionContainer, StyledTable } from '../styles';

function Admins() {
  const [admins, setAdmins] = useState([]);
  const [newAdmin, setNewAdmin] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useUser();
  const history = useHistory();

  useEffect(() => {
    const getAdmins = async () => {
      setLoading(true);
      try {
        const response = await adminsApi.read();
        if (response.status === 200) {
          setAdmins(response.data);
        }
      } catch (error) {
      }
      setLoading(false);
    }
    getAdmins();
  }, [newAdmin]);

  function redirectToLogin() {
    logout();
    history.push('/login');
  }

  async function createAdmin(values) {
    const { name, email, password } = values;
    try {
      message.loading({ 
        key: "creating",
        content: "Cadastrando admin...",
        duration: 9999
      });
      const response = await adminsApi.create({
        name,
        email,
        password
      });
      if (response.status === 200) {
        setNewAdmin(response.data);
        message.destroy("creating");
        message.success("Admin cadastrado com sucesso!");
      }
    } catch (error) {
      message.destroy("creating");
      message.error("Erro ao cadastrar o admin, tente novamente...");
    }
  }

  async function updateAdmin(values) {
    const { id, name, email, password } = values;
    try {
      message.loading({ 
        key: "updating",
        content: "Editando admin...",
        duration: 9999
      });
      const response = await adminsApi.update({
        id,
        name,
        email,
        password
      });
      if (response.status === 200) {
        (user.id === response.data.id && setUser(response.data))
        setNewAdmin(response.data);
        message.destroy("updating");
        message.success("Admin editado com sucesso!");
      }
    } catch (error) {
      message.destroy("updating");
      message.error("Erro ao editar o admin, tente novamente...");
    }
  }

  async function deleteAdmin(id) {
    if (user.id === id && user.level === 0) {
      message.warning("Não é possível deletar o admin master!");
    } else try {
      message.loading({ 
        key: "deleting",
        content: "Removendo admin...",
        duration: 9999
      });
      const response = await adminsApi.delete(id);
      if (response.status === 200) {
        setNewAdmin(response.data);
        (user.id === id) ?
        redirectToLogin()
        :
        message.destroy("deleting");
        message.success("Admin removido com sucesso!");
      }
    } catch (error) {
      console.log(error)
      message.destroy("deleting");
      message.error("Erro ao remover admin, tente novamente...");
    }
  }

  const columns = [
    {
      title: 'Nome',
      key: 'name',
      dataIndex: 'name',
      ellipsis: 'true'
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      ellipsis: 'true'
    },
    {
      title: 'Configurações',
      key: 'config',
      ellipsis: 'true',
      render: (admin) => (
        (user.id === admin.id || user.level === 0) &&
        <AdminManager 
          admin={admin}
          onDelete={deleteAdmin}
          onUpdate={updateAdmin}
        />
      ),
    },
  ];

  return (
    <SectionContainer>
      { user.level === 0 
      ? 
        <AddButton>
          <CreateAdmin onCreate={createAdmin} />
        </AddButton>
      :
        null }
      <StyledTable scroll={{ x: true }} pagination={false} loading={loading}
        columns={columns} dataSource={admins} rowKey="id" />
    </SectionContainer>
  );
}

export default Admins;
