import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { isAuthenticated } from './services/auth';

import Login from './pages/Login';
import Form from './pages/Form';
import Confirm from './pages/Confirm';
import System from './pages/System';
import Home from './pages/System/Home';
import Clients from './pages/System/Clients';
import Admins from './pages/System/Admins';
import Management from './pages/System/Management';
import Birds from './pages/System/Management/Birds';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    isAuthenticated() ?
      <Component {...props} />
      :
      <Redirect to='/login' />
  )} />
);

export const SystemRoutes = () => {
  return (
    <Switch>
      <PrivateRoute exact path='/system' component={Home} />
      <PrivateRoute path='/system/clients' component={Clients} />
      <PrivateRoute path='/system/admins' component={Admins} />
      <PrivateRoute path='/system/management' component={Management} />
      <PrivateRoute path='/system/management/birds' component={Birds} />
    </Switch>
  );
}

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Form} />
        <Route path='/confirm' component={Confirm} />
        <Route path='/login' component={Login} />
        <PrivateRoute path='/system' component={System} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
