import React, { createContext, useState, useContext, useEffect } from 'react';

import { birdsApi } from '../services';

const BirdsContext = createContext();

function BirdsProvider({ children }) {
  const [birds, setBirds] = useState([]);
  const [aux, setAux] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getBirds = async () => {
      try {
        const response = await birdsApi.read();
        if (response.status === 200) {
          setBirds(response.data.sort((a, b) => a.name.localeCompare(b.name)));
        }
      } catch (error) {
        setBirds([]);
      }
    }
    getBirds();
    setLoading(false);
  }, [aux]);

  return (
    <BirdsContext.Provider value={{ 
      birds, 
      setBirds, 
      aux, 
      setAux, 
      loading, 
      setLoading 
    }}>
      {children}
    </BirdsContext.Provider>
  )
}

export default BirdsProvider;

export function useBirds() {
  const context = useContext(BirdsContext);
  const { birds, setBirds, aux, setAux, loading, setLoading } = context;

  return { birds, setBirds, aux, setAux, loading, setLoading };
}