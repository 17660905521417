import React, { useState, useMemo } from 'react';
import { DirectionsRenderer, GoogleMap } from '@react-google-maps/api';

const mapContainerStyle = {
  width: '100%',
  height: '500px',
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

function Direction({ latO, lngO, latD, lngD }) {
  const [directions, setDirections] = useState(null);

  const onMapLoad = useMemo(() => {
    const directionsService = new window.google.maps.DirectionsService()

    directionsService.route({
      origin: {lat: latO, lng: lngO},
      destination: {lat: latD, lng: lngD},
      travelMode: "DRIVING"
    }, (res) => {
      setDirections(res)
    })
  }, [latO, lngO, latD, lngD])

  return (
    <GoogleMap
    mapContainerStyle={mapContainerStyle}
    options={options}
    zoom={15}
    defaultCenter={{
      lat: latO,
      lng: lngO
    }}
    onLoad={onMapLoad}
  >
    {directions ? <DirectionsRenderer directions={directions}/> : null}
  </GoogleMap>
  );
}

export default Direction;