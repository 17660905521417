import React, { useState } from 'react';

import { FiEdit } from 'react-icons/fi';

import { Modal, Select } from 'antd';

import Input from '../../../Input';
import NumberInput from '../../../NumberInput';

import { DropdownItem, ModalContainer, ButtonsContainer, 
  CancelButton, ConfirmButton, CategoryInput } from '../../styles';

function UpdateProduct({ product, onUpdate, categories }) {
  const id = product.productId;
  const [categoryId, setCategoryId] = useState(product.category.id);
  const [name, setName] = useState(product.name);
  const [price, setPrice] = useState((product.price).toFixed(2));
  const [visible, setVisible] = useState(false);
  const { Option } = Select;

  function handleUpdate(e) {
    e.preventDefault();

    onUpdate({
      id,
      categoryId,
      name,
      price
    });

    setVisible(false);
  }

  function handleChange(value) {
    setCategoryId(value);
  }

  return (
    <>
      <DropdownItem onClick={() => setVisible(true)}>
        <FiEdit /> Editar
      </DropdownItem>
      <Modal
        title="Editar produto"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <ModalContainer>
          <form onSubmit={handleUpdate}>
            <Input
              type="text"
              name="name"
              label="Nome"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
            <CategoryInput>
              <label>Categoria</label>
              <Select 
                value={categoryId} 
                style={{ width: "100%" }} 
                size="large"
                onChange={handleChange} 
                placeholder="Selecione uma categoria"
              >
                {
                  categories.map(category => (
                    <Option key={category.id} value={category.id}>{category.name}</Option>
                  ))
                }
              </Select>
            </CategoryInput>
            <NumberInput
              name="price"
              label="Preço"
              value={price}
              onChange={e => setPrice(e.target.value)}
              required
            />
            <ButtonsContainer>
              <CancelButton type="reset" onClick={() => setVisible(false)}>
                Cancelar
              </CancelButton>
              <ConfirmButton type="submit">
                Editar
              </ConfirmButton>
            </ButtonsContainer>
          </form>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default UpdateProduct;