import React, { useEffect, useState } from 'react';

import { ConfirmPage } from './styles';

function Confirm() {
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    setTimeout(() => {
      window.location.href = "https://avesmansas.com.br/";
    }, 5000);
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
  }, [countdown]);

  return(
    <ConfirmPage>
      <span>Respostas enviadas.</span>
      <p>Obrigado,</p> 
      <p>Entraremos em contato assim que possível!</p>
      <p>Redirecionando em {countdown}...</p>
    </ConfirmPage>
  );
}

export default Confirm;