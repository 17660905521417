import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { useCenter } from '../../../context/Center';
import { useSelected } from '../../../context/Selected';
import { clientsApi } from '../../../services';

import { FiEye } from 'react-icons/fi';
import { IoIosPin } from 'react-icons/io';

import { message } from 'antd';

import Maps from '../../../components/Maps';
import ClientManager from '../../../components/Managers/ClientManager';

import { SectionContainer, StyledTable } from '../styles';

function Clients() {
  const [clients, setClients] = useState([]);
  const [newClient, setNewClient] = useState({});
  const [loading, setLoading] = useState(false);

  const { setCenter } = useCenter();
  const { setSelected } = useSelected();

  function handleChosenBird(bird) {
    switch (bird) {
      case 1:
        return 'Calopsita';
      case 2:
        return 'Agapornis';
      case 3:
        return 'Ring neck';
      case 4:
        return 'Canário';
      case 5:
        return 'Periquito Australiano';
      case 6:
        return 'Periquito Inglês';
      case 7:
        return 'Red Rumped';
      case 8:
        return 'Rosela';
      case 9:
        return 'Outro';
      default:
        return;
    }
  }

  function handlePinColor(color) {
    if (color === 'green')
      return <IoIosPin title="Verde" style={{ color: 'var(--color-primary)' }} />;
    else if (color === 'yellow')
      return <IoIosPin title="Amarelo" style={{ color: '#FEDD00' }} />;
    else if (color === 'red')
      return <IoIosPin title="Vermelho" style={{ color: '#EF3340' }} />;
  }

  useEffect(() => {
    const getClients = async () => {
      setLoading(true);
      try {
        const response = await clientsApi.read();
        if (response.status === 200) {
          const newClients = response.data.sort((a, b) =>
            new Date(...b.createdAt.split('/').reverse()) -
            new Date(...a.createdAt.split('/').reverse()))
            .map(newClient => {
              return {
                id: newClient.id,
                name: newClient.name,
                bird: handleChosenBird(newClient.age),
                whatsApp: newClient.whatsApp,
                postalCode: newClient.postalCode,
                interest: newClient.interest,
                time: newClient.time,
                visit: newClient.visit,
                color: newClient.color,
                lat: newClient.lat,
                lon: newClient.lon,
                freight: newClient.freight,
                createdAt: newClient.createdAt,
                updatedAt: newClient.updatedAt
              }
            })
          setClients(newClients);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    getClients();
  }, [newClient]);

  async function updateClient(values) {
    try {
      message.loading({
        key: "updating",
        content: "Editando cliente...",
        duration: 9999
      });
      const response = await clientsApi.update(values);
      if (response.status === 200) {
        setNewClient(response.data);
        message.destroy("updating");
        message.success("Cliente editado com sucesso!");
      }
    } catch (error) {
      message.destroy("updating");
      message.error("Erro ao editar o cliente, tente novamente...");
    }
  }

  async function deleteClient(id) {
    try {
      message.loading({
        key: "deleting",
        content: "Removendo cliente...",
        duration: 9999
      });
      const response = await clientsApi.delete(id);
      if (response.status === 200) {
        setNewClient(response.data);
        message.destroy("deleting");
        message.success("Cliente removido com sucesso!");
      }
    } catch (error) {
      message.destroy("deleting");
      console.log(error);
      message.error("Cliente não removido...");
    }
  }

  function mapView(client) {
    setCenter({
      lat: client.lat,
      lng: client.lon
    });

    setSelected(client);
  }

  const columns = [
    {
      title: 'Marcação',
      key: 'pin',
      dataIndex: 'color',
      render: (color) => handlePinColor(color),
      filters: [
        {
          text: <IoIosPin title="Verde" style={{ color: 'var(--color-primary)' }} />,
          value: 'green'
        },
        {
          text: <IoIosPin title="Amarelo" style={{ color: '#FEDD00' }} />,
          value: 'yellow'
        },
        {
          text: <IoIosPin title="Vermelho" style={{ color: '#EF3340' }} />,
          value: 'red'
        },
      ],
      onFilter: (value, record) => record.color.indexOf(value) === 0,
    },
    {
      title: 'Data da resposta',
      key: 'date',
      dataIndex: 'createdAt',
      render: (date) => moment(date).format('DD/MM/YYYY'),
      ellipsis: true,
    },
    {
      title: 'Nome',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Ave',
      key: 'bird',
      dataIndex: 'bird',
    },
    {
      title: 'WhatsApp',
      key: 'whatsApp',
      dataIndex: 'whatsApp',
      ellipsis: true,
    },
    {
      title: 'CEP',
      key: 'postalCode',
      dataIndex: 'postalCode',
      ellipsis: true,
    },
    {
      title: 'Frete',
      key: 'freight',
      dataIndex: 'freight',
      render: (freight) => (
        `R$${freight.toFixed(2)}`
      ),
      ellipsis: true,
    },
    {
      title: 'Ver',
      key: 'mapView',
      render: (client) => (
        <FiEye onClick={() => mapView(client)} />
      )
    },
    {
      title: 'Gerenciar',
      key: 'manage',
      render: (client) => (
        <ClientManager
          client={client}
          onDelete={deleteClient}
          onUpdate={updateClient}
        />
      ),
    },
  ];

  return (
    <SectionContainer>
      <Maps clients={clients} />
      <StyledTable scroll={{ x: true }} pagination={true} loading={loading}
        columns={columns} dataSource={clients} rowKey="id" />
    </SectionContainer>
  );
}

export default Clients;