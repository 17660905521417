import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLoadScript } from '@react-google-maps/api';

import Geocode from "react-geocode";

import { clientsApi } from '../../services';

import { message, Select } from 'antd';

import NumberInput from '../../components/NumberInput';
import FormTitle from '../../components/FormTitle';
import Header from '../../components/Header';
import Radio from '../../components/Radio';
import Input from '../../components/Input';
import Distance from '../../components/Distance';
import TermsAndConditions from '../../components/TermsAndConditions';
import Footer from '../../components/Footer';
import Direction from '../../components/Direction';

import { FormPage, RadioGroup, InputGroup, CalculateFreight, StyledModal, SelectBlock } from './styles';

const libraries = ["places"];

function Form() {
  const [name, setName] = useState('');
  const [age, setAge] = useState(null);
  const [whatsApp, setWhatsApp] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [interest, setInterest] = useState(null);
  const [time, setTime] = useState(null);
  const [visit, setVisit] = useState(null);
  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);
  const [freight, setFreight] = useState(null);
  const [distance, setDistance] = useState(null);
  const [aux, setAux] = useState(null);
  const [visible, setVisible] = useState(false);

  const formRef = useRef(null);

  const latO = -23.69389089399254;
  const lngO = -46.65878373635833;
  const { Option } = Select;

  const history = useHistory();

  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
  Geocode.setLanguage("pt-br");
  Geocode.setRegion("br");

  async function calculateFreight() {
    try {
      if(formRef.current.reportValidity()){
        if (age === null) {
          return (
            message.warning("Por favor, escolha uma ave!")
          );
        }
        message.loading({
          key: "calculating",
          content: "Calculando frete...",
          duration: 9999
        });
        const response = await Geocode.fromAddress(postalCode);
        if (response.status === "OK") {
          for (let i = 0; i < response.results[0].address_components.length; i++) {
            for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
              if (response.results[0].address_components[i].types[j] === 'country') {
                if (response.results[0].address_components[i].long_name === 'Brasil') {
                  const { lat, lng } = response.results[0].geometry.location;
                  setLat(lat);
                  setLon(lng);
                  setAux("OK");
                  message.destroy("calculating");
                }
              }
            }
          }
        }
      }
    } catch (error) {
      message.destroy("calculating");
      message.error("Erro ao calcular o frete, por favor insira o seu CEP e tente novamente...", 3);
      setLat(null);
      setLon(null);
      setFreight(null);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (freight === null) {
      return (
        message.warning("Por favor, calcule o frete!")
      );
    }

    if (age === null) {
      return (
        message.warning("Por favor, escolha uma ave!")
      );
    }

    try {
      message.loading({
        key: "sending",
        content: "Enviando dados...",
        duration: 9999
      });
      const response = await clientsApi.create({
        name,
        age,
        whatsApp,
        postalCode,
        interest,
        time,
        visit,
        lat,
        lon,
        freight
      });
      console.log(response);
      if (response.status === 200) {
        history.push('/confirm');
        message.destroy("sending");
      }
    } catch (error) {
      message.destroy("sending");
      message.error("Erro ao enviar os dados, por favor, tente novamente...", 3);
    }
  }

  function handleChange(value) {
    setAge(value);
  }

  return (
    <FormPage>
      <Header
        title="Aves Mansas"
        description="Por favor, preencha o formulário para melhor atendermos você!"
      />
      <StyledModal
        title="Termos e condições"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <TermsAndConditions />
      </StyledModal>

      <main>
        <form onSubmit={handleSubmit} ref={formRef}>
          <fieldset>
            <FormTitle title="Dados" />
            <InputGroup>
              <Input
                type="text"
                name="name"
                label="Nome"
                value={name}
                onChange={e => setName(e.target.value)}
                required
              />
              <SelectBlock>
                <label>Qual ave teria interesse?</label>
                <Select
                  value={age}
                  style={{ width: "100%" }}
                  size="large"
                  placeholder="Escolha uma ave"
                  onChange={handleChange}
                  required
                >
                  <Option value={1}>Calopsita</Option>
                  <Option value={2}>Agapornis</Option>
                  <Option value={3}>Ring neck</Option>
                  <Option value={4}>Canário</Option>
                  <Option value={5}>Periquito Australiano</Option>
                  <Option value={6}>Periquito Inglês</Option>
                  <Option value={7}>Red Rumped</Option>
                  <Option value={8}>Rosela</Option>
                  <Option value={9}>Outro</Option>
                </Select>
              </SelectBlock>
              <NumberInput
                name="whatsApp"
                label="WhatsApp"
                format="(##)#####-####"
                mask="_"
                pattern={"[(][0-9]{2}[)][0-9]{5}-[0-9]{4}"}
                value={whatsApp}
                onChange={e => setWhatsApp(e.target.value)}
                required
              />
              <CalculateFreight>
                <NumberInput
                  name="postalCode"
                  label="Calcular frete"
                  placeholder="CEP"
                  format="#####-###"
                  mask="_"
                  pattern={"[0-9]{5}-[0-9]{3}"}
                  value={postalCode}
                  onChange={e => { setPostalCode(e.target.value) }}
                  required
                />
                <button type="button" onClick={calculateFreight} >Calcular</button>
              </CalculateFreight>
              {aux ? <Distance
                latO={latO}
                lngO={lngO}
                latD={lat}
                lngD={lon}
                setFreight={setFreight} 
                setDistance={setDistance}/> : null}
              {freight ? (
                <>
                  <b> R$ {freight} </b>
                  <b> Distância ida e volta a ser percorrida: {distance}km</b>
                  <b>
                    * Nem todas entregas são viáveis considerando que estamos
                    lidando com filhotes e distância a ser percorrida.
                    Nossa equipe analisa cada caso individualmente.
                  </b>
                  <b>
                    ** Valores podem variar em função do horário e data da entrega.
                  </b>
                  <Direction
                    latO={latO}
                    lngO={lngO}
                    latD={lat}
                    lngD={lon}
                  />
                </>
              ) : null}
            </InputGroup>
          </fieldset>

          <fieldset>
            <FormTitle title="Informações" />
            <RadioGroup onChange={e => setInterest(e.target.value)}>
              <p>Qual seu nível de interesse pela ave?</p>
              <Radio
                required={true}
                id="Q1O1"
                name="Q1"
                label="Já decidi pela aquisição"
                value={0}
              />
              <Radio
                id="Q1O2"
                name="Q1"
                label="Ainda estou avaliando a possibilidade"
                value={1}
              />
              <Radio
                id="Q1O3"
                name="Q1"
                label="Tenho apenas curiosidade no momento"
                value={2}
              />
            </RadioGroup>
            <RadioGroup onChange={e => setTime(e.target.value)}>
              <p>Quando imagina a nova ave em seu lar?</p>
              <Radio
                required={true}
                id="Q2O1"
                name="Q2"
                label="Ainda essa semana"
                value={0}
              />
              <Radio
                id="Q2O2"
                name="Q2"
                label="Nas próximas semanas"
                value={1}
              />
              <Radio
                id="Q2O3"
                name="Q2"
                label="Nos próximos meses"
                value={2}
              />
            </RadioGroup>
            <RadioGroup onChange={e => setVisit(e.target.value)}>
              <p>Após ver a localização do Criadouro (Rua Padre Simão Vasconcelos 64,
              Balneário Mar Paulista. CEP: 04463010), achei:</p>
              <Radio
                required={true}
                id="Q3O1"
                name="Q3"
                label="Totalmente impossível me deslocar até lá"
                value={0}
              />
              <Radio
                id="Q3O2"
                name="Q3"
                label="Possível me deslocar, mas não é algo tão viável no momento"
                value={1}
              />
              <Radio
                id="Q3O3"
                name="Q3"
                label="Viável a visita, mas só tenho curiosidade em saber o valor de frete"
                value={2}
              />
            </RadioGroup>
          </fieldset>

          <footer>
            <div className="terms">
              <input type="checkbox" id="termsOfPrivacy" name="termsOfPrivacy" required />
              <label>
                Compreendo e aceito os
                <span onClick={() => setVisible(true)}> Termos e Condições </span>
                do site.
              </label>
            </div>
            <button type="submit" >Enviar</button>
          </footer>
        </form>
      </main>

      <Footer />
    </FormPage>
  );
}

export default Form;
