import api from './api';

const birdsApi = {
  
  create: (data) => api.post('/bird/register', data),
  read: () => api.get('/bird/allBirds'),
  update: (data) => api.put('/bird/update', data),
  delete: (id) => api.delete(`/bird/delete/${id}`)
}

export default birdsApi;