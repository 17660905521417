import React, { useState, useEffect } from 'react';

import { categoriesApi } from '../../../../services';
import { useCategories } from '../../../../context/Categories';

import { message } from 'antd';

import CreateCategory from '../../../../components/Managers/CategoryManager/CreateCategory';
import CategoryManager from '../../../../components/Managers/CategoryManager';

import { AddButton, StyledTable } from '../../styles';

function Categories() {
  const { categories, setAux, loading } = useCategories();
  const [newCategory, setNewCategory] = useState(null);

  useEffect(() => {
    setAux(newCategory);
  }, [newCategory]);

  async function createCategory(values) {
    const { name, type } = values;
    try {
      message.loading({
        key: "creating",
        content: "Cadastrando categoria...",
        duration: 9999
      });
      const response = await categoriesApi.create({
        name,
        type
      });
      if (response.status === 200) {
        console.log(response)
        setNewCategory(response.data);
        message.destroy("creating");
        message.success("Categoria cadastrada com sucesso!");
      }
    } catch (error) {
      console.log(error)
      message.destroy("creating");
      message.error("Erro ao cadastrar a categoria, tente novamente...");
    }
  }

  async function updateCategory(values) {
    const { id, name, type } = values;
    try {
      message.loading({ 
        key: "updating",
        content: "Editando categoria...",
        duration: 9999
      });
      const response = await categoriesApi.update({
        id,
        name,
        type
      });
      if (response.status === 200) {
        setNewCategory(response.data);
        message.destroy("updating");
        message.success("Categoria editada com sucesso!");
      }
    } catch (error) {
      message.destroy("updating");
      message.error("Erro ao editar a categoria, tente novamente...");
    }
  }

  async function deleteCategory(id) {
    try {
      message.loading({ 
        key: "deleting",
        content: "Removendo categoria...",
        duration: 9999
      });
      const response = await categoriesApi.delete(id);
      if (response.status === 200) {
        setNewCategory(response.data);
        message.destroy("deleting");
        message.success("Categoria removida com sucesso!");
      }
    } catch (error) {
      message.destroy("deleting");
      message.error("Erro ao remover categoria, tente novamente...");
    }
  }

  function handleType(type) {
    if (type === "bird")
      return "Ave"
    else if (type === "product")
      return "Produto"
  }

  const columns = [
    {
      title: 'Nome',
      key: 'name',
      dataIndex: 'name',
      ellipsis: 'true'
    },
    {
      title: 'Categoria',
      key: 'type',
      dataIndex: 'type',
      render: (type) => handleType(type),
      filters: [
        {
          text: 'Ave',
          value: 'bird'
        },
        {
          text: 'Produto',
          value: 'product'
        }
      ],
      onFilter: (value, record) => record.type.indexOf(value) === 0,
      ellipsis: 'true'
    },
    {
      title: 'Configurações',
      key: 'config',
      render: (category) => (
        <CategoryManager 
          category={category}
          onDelete={deleteCategory}
          onUpdate={updateCategory}
        />
      ),
    },
  ];

  return (
    <>
      <AddButton>
        <CreateCategory onCreate={createCategory} />
      </AddButton>
    <StyledTable scroll={{ x: true }} pagination={true} loading={loading}
      columns={columns} dataSource={categories} rowKey="id" />
    </>
  );
}

export default Categories;
