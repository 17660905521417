import React, { createContext, useState, useContext } from 'react';

const CenterContext = createContext();

function CenterProvider({ children }) {
  const [center, setCenter] = useState({
    lat: -23.69389089399254,
    lng: -46.65878373635833,
  });

  return (
    <CenterContext.Provider value={{ center, setCenter }}>
      {children}
    </CenterContext.Provider>
  )
}

export default CenterProvider;

export function useCenter() {
  const context = useContext(CenterContext);
  const { center, setCenter } = context;

  return { center, setCenter };
}