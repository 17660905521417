import React, { useState } from 'react';

import { FiPlus } from 'react-icons/fi';

import { Modal } from 'antd';

import Input from '../../../Input';

import { ModalContainer, RegisterButton } from '../../styles';

function CreatePayment({ onCreate }) {
  const [name, setName] = useState('');
  const [visible, setVisible] = useState(false);

  async function handleCreate(e) {
    e.preventDefault();

    onCreate({
      name
    });

    setVisible(false);
    setName('');
  }

  return (
    <>
      <button type="button" onClick={() => setVisible(true)} title="Cadastrar">
        <FiPlus />
      </button>
      <Modal
        title="Cadastrar via de pagamento"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <ModalContainer>
          <form onSubmit={handleCreate}>
            <Input
              type="text"
              name="name"
              label="Nome"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
            <RegisterButton type="submit">Cadastrar</RegisterButton>
          </form>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default CreatePayment;