import React, { createContext, useState, useContext, useEffect } from 'react';

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("user");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("user", serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};

const UserContext = createContext();

function UserProvider({ children }) {
  const [user, setUser] = useState(() => loadState());

  useEffect(() => {
    saveState(user);
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider;

export function useUser() {
  const context = useContext(UserContext);
  const { user, setUser } = context;

  return { user, setUser };
}