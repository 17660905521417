import React from 'react';

import { SystemRoutes } from '../../routes';

import CenterProvider from '../../context/Center';
import SelectedProvider from '../../context/Selected';
import CategoriesProvider from '../../context/Categories';
import ProductsProvider from '../../context/Products';
import BirdsProvider from '../../context/Birds';
import PaymentsProvider from '../../context/Payments';

import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

import { NavRouter } from './styles';

function System() {
  return (
    <CenterProvider>
      <SelectedProvider>
        <CategoriesProvider>
          <BirdsProvider>
            <ProductsProvider>
              <PaymentsProvider>
                <NavRouter>
                  <Navbar />
                  <SystemRoutes />
                  <Footer />
                </NavRouter>
              </PaymentsProvider>
            </ProductsProvider>
          </BirdsProvider>
        </CategoriesProvider>
      </SelectedProvider>
    </CenterProvider>
  );
}

export default System;
