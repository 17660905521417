import React, { useEffect, useState } from 'react';

import { productsApi } from '../../../../services';
import { useCategories } from '../../../../context/Categories';
import { useProducts } from '../../../../context/Products';

import ProductManager from '../../../../components/Managers/ProductManager';
import CreateProduct from '../../../../components/Managers/ProductManager/CreateProduct';
import { message } from 'antd';

import { AddButton, StyledTable } from '../../styles';

function Products() {
  const { categories } = useCategories();
  const [productsCategories, setProductsCategories] = useState([]);
  const { products, setAux, loading } = useProducts();
  const [newProduct, setNewProduct] = useState({});

  useEffect(() => {
    setAux(newProduct);
  }, [newProduct]);

  useEffect(() => {
    setProductsCategories(categories.filter(category => category.type === 'product'));
  }, [categories]);

  async function createProduct(values) {
    const { categoryId, name, price } = values;
    try {
      message.loading({
        key: "creating",
        content: "Cadastrando produto...",
        duration: 9999
      });
      const response = await productsApi.create({
        categoryId,
        name,
        price
      });
      if (response.status === 200) {
        setNewProduct(response.data);
        message.destroy("creating");
        message.success("Produto cadastrado com sucesso!");
      }
    } catch (error) {
      console.log(error)
      message.destroy("creating");
      message.error("Erro ao cadastrar o produto, tente novamente...");
    }
  }

  async function updateProduct(values) {
    const { id, categoryId, name, price } = values;
    try {
      message.loading({ 
        key: "updating",
        content: "Editando produto...",
        duration: 9999
      });
      const response = await productsApi.update({
        id, 
        categoryId, 
        name, 
        price
      });
      if (response.status === 200) {
        setNewProduct(response.data);
        message.destroy("updating");
        message.success("Produto editado com sucesso!");
      }
    } catch (error) {
      message.destroy("updating");
      message.error("Erro ao editar o produto, tente novamente...");
    }
  }

  async function deleteProduct(id) {
    try {
      message.loading({ 
        key: "deleting",
        content: "Removendo produto...",
        duration: 9999
      });
      const response = await productsApi.delete(id);
      if (response.status === 200) {
        console.log(response)
        setNewProduct(response.data);
        message.destroy("deleting");
        message.success("Produto removido com sucesso!");
      }
    } catch (error) {
      message.destroy("deleting");
      message.error("Erro ao remover o produto, tente novamente...");
    }
  }

  const columns = [
    {
      title: 'Produto',
      key: 'product',
      dataIndex: 'name',
    },
    {
      title: 'Categoria',
      key: 'category',
      dataIndex: 'category',
      render: (category) => category.name,
      filters: productsCategories.map(category => (
        {
          text: category.name,
          value: category.name
        }
      )),
      onFilter: (value, record) => record['category'].name.indexOf(value) === 0,
    },
    {
      title: 'Preço',
      key: 'price',
      dataIndex: 'price',
      render: (price) => (
        `R$${price.toFixed(2)}`
      ),
      ellipsis: true,
    },
    {
      title: 'Configurações',
      key: 'config',
      render: (product) => (
        <ProductManager 
          product={product}
          categories={productsCategories}
          onDelete={deleteProduct}
          onUpdate={updateProduct}
        />
      ),
    },
  ];

  return (
    <>
      <AddButton>
        <CreateProduct onCreate={createProduct} categories={productsCategories} />
      </AddButton>
      <StyledTable scroll={{ x: true }} pagination={true} columns={columns} 
        dataSource={products} loading={loading} rowKey="productId" />
    </>
  );
}

export default Products;
