import React from 'react';

import { useUser } from '../../../context/User';

import { HomeSection } from './styles';

function Home() {
  const { user } = useUser();

  return (
    <HomeSection>
      <img src="/assets/favicon.png" alt="Aves Mansas" />
      <h1>Bem vindo {user.name}, ao sistema Aves Mansas!</h1>
    </HomeSection>
  );
}

export default Home;